import React from 'react';
import './AudioPlayer.css';
import Needle from './Needle';


function DrawBottomMeasureGrid(mode, ctx, currentCanvas, audioLength, wrapper) {
  //console.log(wrapper)
  //console.log("DrawBottomMeasureGrid")
  //console.log(audioLength)
  //console.log(currentCanvas)
  //console.log(ctx)
  var pointsX = audioLength > 15 ? 29 : Math.floor(audioLength / 0.5);
  pointsX = 10;
  var canvasWidth = currentCanvas.offsetWidth;
  //console.log(canvasWidth)

  ctx.fillStyle = '#ffffff';
  ctx.strokeStyle = '#1f5fc8';
  ctx.fillStyle = '#1f5fc8';
  ctx.font = '10px Arial';
  ctx.textAlign = 'center';
  ctx.beginPath();
  var recommendedOfsetX = wrapper.offsetWidth / 30;
  
  var offsetFactorY = ctx.canvas.height / 269;

  //console.log("wrapper.offsetWidth", wrapper.offsetWidth)
  var recommendedBottomGreedMarkerCount = canvasWidth / recommendedOfsetX;
  //var offsetFactorX;
  //console.log("points", pointsX)
  //console.log("recommendedBottomGreedMarkerCount", recommendedBottomGreedMarkerCount)
  if(audioLength <= 10){
      var offsetX = pointsX  / audioLength * canvasWidth / pointsX;

      //offsetFactorX = 1192 / currentCanvas.offsetWidth
      ctx.fillText('0.0', 0, 257);
      for(var i = 0; i < pointsX + 1; i++) {

          ctx.moveTo(0 + (i * offsetX), 269);

          if(i % 1 === 0){
              ctx.lineTo(0 + (i * offsetX), 260);
              //console.log(i)
              ctx.fillText(((i) ).toFixed(1).toString(), 0 + (i * offsetX), 257);
          }
          else {
              ctx.lineTo(0 + (i * offsetX), 253);

          }
      }
  }
  else {
      var timeOffset = audioLength / recommendedBottomGreedMarkerCount;
      //offsetFactorX = 1192 / currentCanvas.offsetWidth
      ctx.fillText('0.0', 0, 257*offsetFactorY);

      for(var y = 0; y < recommendedBottomGreedMarkerCount; y++) {
          //offsetFactorX = ctx.canvas.width / (y * recommendedOfsetX);
          ctx.moveTo(0 + (y * recommendedOfsetX)*offsetFactorY, 267*offsetFactorY);
          if(y % 2 === 0){
              ctx.lineTo(0 + (y * recommendedOfsetX)*offsetFactorY, 260*offsetFactorY);
              if(y>0){
                  ctx.fillText(((y + 1) * timeOffset).toFixed(1).toString(), 0 + (y * recommendedOfsetX)*offsetFactorY, 257*offsetFactorY);
              }

          }
          else {
              ctx.lineTo(0 + (y * recommendedOfsetX)*offsetFactorY, 253*offsetFactorY);

          }

      }
  }
  ctx.stroke();
}


function DrawSpectrogram(canvas, context, width, img) {
  canvas.width = width;

  //console.log("Spectrogram: In draw Spectrogram, setting width")
  //console.log("Spectrogram: ", context);
  //console.log("Spectrogram:", img)
  function loaded(){
    context.drawImage(img, 0, 30, img.width, img.height, 0,0, canvas.width, canvas.height+16);
    //console.log("Spectrogram: draw image");
  }
  if (img.complete) {
    loaded();
  }
}

class Spectrogram extends React.Component {

  constructor (props){
    super(props)
    this.canvasRef = React.createRef();
    this.wrapperRef = React.createRef();
    this.state = {
      img: new Image(),
    };
  }
  componentDidMount() {
    //console.log("canvasref in componentdidmount", this.canvasRef)
    let img = new Image();
    img.src = this.props.imgPath
    let canvas = this.canvasRef.current;
    let context = canvas.getContext('2d');
    this.setState ({ img: img })
    this.setState ({ canvas: canvas })
    this.setState ({ context: context })
    this.setState({ wrapperRef: this.wrapperRef.current })

  }

  render () {
    if ((this.state.img.complete ) && (this.props.audioLength != 0)) {
      //console.log("spectrogram", this.state.canvas)
      DrawSpectrogram(this.state.canvas, this.state.context, 900, this.state.img);
      DrawBottomMeasureGrid('waveform', this.state.context, this.state.canvas, this.props.audioLength, this.state.wrapperRef);
    }
    return (
      <div>

          <canvas id="measureGreedCanvasSpectrum" className="greed-canvas spectrum" width="840" height="280" style={pStyle}></canvas>
          <div className="analyze-frame-wrapper" ref={this.wrapperRef}>
            <canvas className="analyze" height="270" ref={this.canvasRef} style={tStyle}/>
            <Needle
              processor={this.props.processor}
              analyzer={this.props.analyzer}
              audioLength={this.props.audioLength}
              canvasRef={this.wrapperRef}
              playing={this.props.playing}
              stoppedAt={this.props.stoppedAt}
              resetAudio={this.props.resetAudio}>
          </Needle>
          </div>
      </div>
    );
  }
}

const pStyle = {
  display: 'block',
  backgroundColor: '#f6f9ff'};

const tStyle = {
  display: 'block',
};


export default Spectrogram;


import React from 'react';
import { Row, Col } from 'reactstrap';

class BloodTests extends React.Component {
 
  render(){
    return (
    <div>
    <Row>

      <Col md={{ size: 12, order: 0, offset: 0 }}>

      <div className="content-block">
        <h3 className="upper">Blood tests</h3>
        <p><strong>Hemoglobin</strong></p>
        <p>
          Reduced values may give shortness of breath. Increased values are sometimes seen in pulmonary diseases
        </p>
        <p><strong>C-reactive protein (CRP) </strong></p>
        <p>Increased values are seen during infections and in patients with inflammatory diseases or cancer. Slightly increased values, only detected by sensitive CRP test (within 0-5 mg/L), are associated with heart diseases and Chronic Obstructive Pulmonary Disease (COPD)</p>
        <p><strong>N-terminal pro b-type Natriuretic Peptide (NT-proBNP)</strong></p>
        <p>Increased values are seen in heart failure. The diagnosis of heart failure is partly based on NT-proBNP when left ventrcular ejection fraction is normal or only moderately reduced.</p>
      </div>
      </Col>
    </Row>
    <Row>
          <Col md={{ size: 6, order: 0, offset: 0 }}>

      <div className="intro-image-container">
        <div className="bloodtests-image">
      </div>
    </div>
    </Col>
    </Row>

    </div>

    )
  }
}

export default BloodTests;

import { useEffect } from "react";

import "./caseDetails.css";
import { Row, Col } from "reactstrap";

//import BlueBigButton from '../components/blueBigButton/blueBigButton';
import CaseStepper from "../components/caseStepper/caseStepper";

import { useParams } from "react-router-dom";
import MaleBackground from "../assets/images/male11-nobackground.jpg";
import FemaleBackground from "../assets/images/female5.jpg";
import { useAppDispatch, useAppSelector } from "../hooks";
import { fetchCaseDetails } from "../reducers/caseSlice";
import BlueBigButton from "../components/blueBigButton/blueBigButton";

type CaseDetailsParams = {
  moduleId: string;
  caseId: string;
};

const CaseDetails = () => {
  const { moduleId, caseId } = useParams<CaseDetailsParams>();
  const dispatch = useAppDispatch();

  const specificCase = useAppSelector(
    (state) => state.cases.cases[caseId as string]
  );

  useEffect(() => {
    if (moduleId && caseId) {
      dispatch(fetchCaseDetails({ moduleId, caseId }));
    }
  }, [dispatch, moduleId, caseId]);

  const renderBackground = (gender: string) => {
    if (gender === "female") {
      return (
        <div
          className="patient-image"
          style={{
            background:
              `url(${FemaleBackground})` + "no-repeat center center / contain",
          }}
        ></div>
      );
    } else if (gender === "male") {
      return (
        <div
          className="patient-image"
          style={{
            background:
              `url(${MaleBackground})` + "no-repeat center center / contain",
          }}
        ></div>
      );
    }
  };

  if (specificCase === undefined || specificCase.details === undefined) {
    return <div>Loading...</div>;
  }

  const newTo: string = `/modules/${moduleId}/cases/${caseId}/lung_auscultation`;

  return (
    <div className="container">
      <div className="main-content">
        {<CaseStepper activeStep={0} />}
        <div className="case-details-container">
          <Row>
            <Col md={{ size: 3, order: 1, offset: 0 }}>
              <div className="patient-container">
                <div className="patient-content">
                  {renderBackground(specificCase.details.gender)}
                </div>
              </div>
            </Col>
            <Col md={{ size: 9, order: 2, offset: 0 }}>
              <div className="patient-info">
                <ul>
                  <li>
                    <span className="marker">{specificCase.name}</span>
                  </li>
                  <li>
                    <span className="marker">Description:</span>{" "}
                    {specificCase.title}
                  </li>
                  <li>
                    <span className="marker">History:</span>{" "}
                    {specificCase.details.description}
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
        <Row>
          <Col md={{ size: 2, order: 0, offset: 10 }}>
            <div>
              <div className="button-block case-button">
                {<BlueBigButton link={newTo} text="next" />}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CaseDetails;

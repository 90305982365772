import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import { faCog  } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ResetPopup from './resetPopup/resetPopup';

const useStyles = makeStyles(theme => ({
  paper: {
    marginRight: theme.spacing(2),
  },
}));


export default function MenuListComposition() {

  const newRedirect = "no"
  const [openPopup, setopenPopup] = React.useState("no");
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  //console.log("we are in menu list MenuListComposition")

  function handleToggle() {
    setOpen(prevOpen => !prevOpen);
  }

  function handleClose(event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  }
  function handleListKeyDown() {
    //if (event.key === 'Tab') {
    //  event.preventDefault();
    //  setOpen(false);
    //}
  }

  function handlePopupToggle() {
    setopenPopup("yes")
    setOpen(false)
  }


  function closePopup() {
    setopenPopup("no")
  }

  function showPopup() {
    if (openPopup == "yes") {
      return(<ResetPopup  closePopup = {closePopup}></ResetPopup>)
    }
  }
  

  //console.log(newRedirect)
  if (newRedirect === "no"){
    return (
<div className={classes.root}>
      {showPopup()}
      <Paper className={classes.paper}>

      </Paper>
      <div>
          <Button
            ref={anchorRef}
            aria-controls="menu-list-grow"
            aria-haspopup="true"
            onClick={handleToggle}
          >
                    <div className="arrow">
                    <FontAwesomeIcon icon={faCog} size="lg" style={{color: "#ffffff",}} />
                    </div>
          </Button>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>



                      <MenuItem color="primary" onClick={() => handlePopupToggle()}>Restart</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
    );
  }
}

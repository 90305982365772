import React from 'react';

class Interactivity extends React.Component {
 
  render(){
    return (
    <div>
    <div className="content-block">
      <h3 className="upper">Interactivity</h3>
      <p>
        To enhance concentrated learning, we have applied forced interactivity. After listening to a recording, you have to classify the sounds to be able to proceed. When looking at the results of supplementary testing, you have to decide whether it is normal or not. Feed-back is promptly given.
     </p>
    </div>
    <div className="content-block">
      <h3 className="upper">Overall feedback</h3>
      <p>
        As an overall feedback, you get 1-3 points for each case. 
        You get one point for a right classification of the lung sound and one point for a right classification of the heart sound. When you choose the most relevant test for a case, you also get a point. 
      </p>
      <p>
        Imagine you work in primary care and have easy access to blood tests, ECG, pulse oximetry and spirometry. 
        You can also refer to echocardiography, but due to the costs with this procedure, you must try to limit its use. 
        For each case, we have deemed two out of the eight test options to be the most relevant next steps in primary care, and you get one point if you select one of these as first test. 
        Based on the setting you work in, the access to testing will vary. You might have good reasons to disagree with our opinions on relevant tests. 
        </p></div>
    <div className="content-block">
      <h3 className="upper">A new try</h3>
      <p>
When you have completed a case, it is possible to listen again to the sound recordings. However, your first classification is used when your points are calculated.
        </p></div>

    </div>
    )
  }
}

export default Interactivity;

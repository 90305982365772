import React from "react";

import "../../App.css";
import "./header.css";
import MenuListComposition from "../menuListComposition"
import { Outlet } from "react-router-dom";

const Header = () => {
  return (
    <div>
    <header>
      <div className="header">
        <div className="header-wrapper">
          <div className="container">
            <div className="header-inner">
              <div className="logo-container">
                <div className="t7-logo"></div>
                <div className="header-link">
                  <a href="/">
                    <h6>Heart and lung sounds from the Tromsø Study</h6>
                  </a>
                </div>
              </div>

              <div className="user-block logedin">
                <div className="welcome">Settings</div>

                {<MenuListComposition></MenuListComposition>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <Outlet />
    </div>
  );
};
export default Header;

import React from 'react';
import { Row, Col } from 'reactstrap';

class IntroOne extends React.Component {
 
  render() {
    return (
    <div>
    <div className="content-block">
      <Row>
      <Col md={{ size: 12, order: 0, offset: 0 }}>

        <h3 className="upper">The team behind:</h3>
      </Col>
      </Row>
      <Row>
      <Col md={{ size: 3, order: 0, offset: 0 }}>
        <p><strong>Contact: </strong><br></br>hasse.melbye@uit.no</p>
      </Col>
      <Col md={{ size: 3, order: 0, offset: 0 }}>

        <p><strong>Project leader: </strong><br></br>Hasse Melbye</p>
      </Col>
      <Col md={{ size: 6, order: 0, offset: 0 }}>

        <p><strong>Funding: </strong><br></br>'Result' and General Practice Research Unit, The Arctic University of Norway, Tromsø © 2020 </p>
      </Col>
      </Row>
      <Row>
      <Col md={{ size: 12, order: 0, offset: 0 }}>

        <div className="intro-image-container">
          <div className="intro-image">
          </div>
        </div>
      </Col>
      </Row>
    </div>
    </div>
    )
  }
}

export default IntroOne;

import React from 'react';
import { Row, Col } from 'reactstrap';

class PrivacyEthics extends React.Component {
 
  render() {
    return (
    <div>
    <Row>
      <Col md={{ size: 12, order: 0, offset: 0 }}>

      <div className="content-block">
        <h3 className="upper">Privacy and ethics</h3>
        <p>The aim of this project was to present scientific results from the Tromsø Study useful for the education of health professionals. It is mainly meant to be a learning tool for better identification and understanding of lung sounds and heart sounds. However, to make it more interesting and clinically relevant for doctors and other users of the stethoscope, we have added data from other investigations such as spirometry and echocardiography.</p>
        <p>
          The data presented comes from participants in the Tromsø Study. We do not know who these persons are, and we are convinced that it is not possible to identify any of them. And further, we believe that none of the participants will be able to recognize themselves. The various information presented on each case are true results,  except when we tell about the participants occupations. These are simulations.
        </p>
        <p>The Tromsø study was approved by The Regional Committee of Medical and Health Research Ethics in North Norway.  The participants gave written consent.</p>
      </div>
      </Col>
    </Row>

    </div>

    )
  }
}

export default PrivacyEthics;

import React from 'react';
import './AudioPlayer.css';
import Spectrogram from './Spectrogram';
import Toolbar from './Toolbar';


async function LoadAudio(audioPath) {
  const analyzeContext = new OfflineAudioContext(1, 15241583, 44100);

  // Fetch the audio data
  const response = await fetch(audioPath);
  console.log(response)
  if (!response.ok) {
    throw new Error(`Network response was not ok: ${response.statusText}`);
  }

  const arrayBuffer = await response.arrayBuffer();
  

  // Decode the audio data
  const dataBuffer = await new Promise((resolve, reject) => {
    analyzeContext.decodeAudioData(arrayBuffer, resolve, reject);
  });

  const audioLength = dataBuffer.length / dataBuffer.sampleRate;

  return { buffer: dataBuffer, audioLength };
}


/* eslint-disable-next-line react/prefer-stateless-function */
class AudioPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.playAudio = this.playAudio.bind(this);
    this.stopAudio = this.stopAudio.bind(this);
    this.resetAudio = this.resetAudio.bind(this);
    this.changeVisualization = this.changeVisualization.bind(this);
    this.state = {
      buffer: new ArrayBuffer(),
      audioLength: 0,
      processor: '',
      analyzer: '',
      playing: false,
      analyzeContext: 'wait',
      stoppedAt: 0,
      startedAt: 0,
      loaded: false,
      waveform: "",
      spectrogram: "active",
    };
  }


  stopAudio() {
    this.state.audio.stop(0);
    const stoppedAt = Date.now() - this.state.startedAt;
    //console.log("stoppedAt", stoppedAt)
    this.setState({ stoppedAt: stoppedAt });
    this.setState({ playing: false });
  }


  /*async componentDidMount() {

  }*/

  async startLoadingAudio(){
    if (this.state.loaded === false){
      const ret = await LoadAudio(this.props.audioPath);
      this.setState({ buffer: ret.buffer });
      this.setState({ audioLength: ret.audioLength });
      this.setState({ loaded: true })
    }
  }
  playAudio() {
    const analyzeContext = new (window.AudioContext || window.webkitAudioContext)();
    const processor = analyzeContext.createScriptProcessor(2048, 1, 1);
    const analyzer = analyzeContext.createAnalyser();
    analyzer.smoothingTimeConstant = 0;
    analyzer.fftSize = 2048;
    const audio = analyzeContext.createBufferSource();
    const splitter = analyzeContext.createChannelSplitter(2);
    const gainNode = analyzeContext.createGain();
    processor.connect(analyzeContext.destination);
    analyzer.connect(processor);

    //console.log(this.state.buffer);
    audio.buffer = this.state.buffer;
    audio.connect(splitter);
    splitter.connect(analyzer, 0, 0);
    gainNode.gain.value = 9;
    audio.connect(gainNode);
    gainNode.connect(analyzeContext.destination);
    //console.log('AUDIO', audio);
    if (this.state.stoppedAt !== 0) {
      //console.log("start pos", this.state.stoppedAt / 1000)
      audio.start(0, this.state.stoppedAt / 1000);
    }
    else {
      audio.start(0)
    }
    const startedAt = Date.now();
    this.setState({ startedAt });
    this.setState({ analyzeContext });
    this.setState({ processor });
    this.setState({ analyzer });
    this.setState({ playing: true });
    this.setState({ audio });
  }
  resetAudio() {
    //console.log("running reset audio");
    this.setState({ playing: false });
  }
  changeVisualization(vis) {
    if (vis === "spectrogram"){
      this.setState({ spectrogram: "active"})
      this.setState({ waveform: "" })
    }
    else {
      this.setState({ spectrogram: ""})
      this.setState({ waveform: "active" })      
    }
  }

  render() {
    if ((this.props.audioPath === undefined) || (this.props.spectrogramPath === undefined)){
      return <div>Loading...</div>
    }
    else {
      this.startLoadingAudio()
    }
    return (
      <div className="analyze-page-wrapper analyze-block">

        <div className="analyze-page-main-content">
          <div className="audio-display-toolbar">
          <a href="javascript:void(0)" 
             data-toggle="tooltip"
             data-placement="top"
             title="Switch to spectrogram view"
             className={"audio-choser spectral "+ this.state.spectrogram}
             onClick={() => this.changeVisualization("spectrogram")}>
          </a>

            <div className={"canvas-wrapper spectrum " + this.state.spectrogram}>
              <Spectrogram
                  buffer={this.state.buffer}
                  audioLength={this.state.audioLength}
                  wrapperRef={this.wrapperRef}
                  analyzeContext={this.state.analyzeContext}
                  processor={this.state.processor}
                  analyzer={this.state.analyzer}
                  playing={this.state.playing}
                  stoppedAt={this.state.stoppedAt}
                  resetAudio={this.resetAudio}
                  width={800}
                  imgPath={this.props.spectrogramPath}
                >
                </Spectrogram>

            </div>
            <Toolbar
              audio={this.state.buffer}
              analyzeContext={this.state.analyzeContext}
              processor={this.state.processor}
              analyzer={this.state.analyzer}
              playAudio={this.playAudio}
              stopAudio={this.stopAudio}
              resetAudio={this.resetAudio}
              playing={this.state.playing}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AudioPlayer;

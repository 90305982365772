import React from "react";

import "../App.css";
import { Row, Col } from "reactstrap";
import BlueBigButton from "../components/blueBigButton/blueBigButton";
import CaseStepper from "../components/caseStepper/caseStepper";
import { useParams } from "react-router-dom";
//import BlueBigButton from './BlueBigButton'

type BeforeFollowupProps = {
  moduleId: string;
  caseId: string;
};

const BeforeFollowup = () => {
  const { moduleId, caseId } = useParams<BeforeFollowupProps>();
  return (
    <div className="container">
      <div className="main-content">
        <CaseStepper activeStep={3}></CaseStepper>
        <div className="content-container">
          <Row>
            <Col md={{ size: 8, order: 0, offset: 2 }}>
              <div className="before-followup">
                <div className="header-text">
                  <p>How should these sounds be interpreted?</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 8, order: 0, offset: 2 }}>
              <div className="header-text">
                <p>Would supplementary examinations be helpful?</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 8, order: 0, offset: 2 }}>
              <div className="header-text">
                <p>
                  You will now be asked to choose between 8 testing options:
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={{ size: 3, order: 0, offset: 2 }}>
              <div className="before-button" style={{ marginLeft: "1rem" }}>
                <BlueBigButton
                  link={`/modules/${moduleId}/cases/${caseId}/followup`}
                  text="Continue"
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default BeforeFollowup;

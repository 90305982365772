import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CaseTestType, ExamsType } from "../models/caseTest";

// Define a type for the slice state
interface CaseTestsState {
  caseTests: Record<string, CaseTestType>;
  isLoading: boolean;
  error: string | null;
}

const initialState: CaseTestsState = {
  caseTests: {},
  isLoading: false,
  error: null,
};

export const caseTestsSlice = createSlice({
  name: "caseTests",
  initialState,
  reducers: {
    addLungAnswer(
      state,
      action: PayloadAction<{ caseId: string; answer: string }>
    ) {
      const { caseId, answer } = action.payload;
      const existingCase = state.caseTests[caseId];
      if (existingCase && existingCase.examsAnswer) {
        existingCase.examsAnswer.lung = answer; // Add details to the case
        existingCase.progress++;
      }
    },
    addHeartAnswer(
      state,
      action: PayloadAction<{ caseId: string; answer: string }>
    ) {
      const { caseId, answer } = action.payload;
      const existingCase = state.caseTests[caseId];
      if (existingCase && existingCase.examsAnswer) {
        existingCase.examsAnswer.heart = answer; // Add details to the case
        existingCase.progress++;
      }
    },
    addFirstChoiceAnswer(
      state,
      action: PayloadAction<{ caseId: string; answer: string }>
    ) {
      const { caseId, answer } = action.payload;
      const existingCase = state.caseTests[caseId];
      if (existingCase && existingCase.examsAnswer) {
        existingCase.examsAnswer.firstChoice = answer; // Add details to the case
        existingCase.progress++;
      }
    },
    addScore(
      state,
      action: PayloadAction<{ caseId: string; score: number }>
    ) {
      const { caseId, score } = action.payload;
      const existingCase = state.caseTests[caseId];
      if (existingCase) {
        existingCase.score = score; // Add details to the case
      }
    },
    addEcgAnswer(
      state,
      action: PayloadAction<{ caseId: string; answer: string }>
    ) {
      const { caseId, answer } = action.payload;
      const existingCase = state.caseTests[caseId];
      console.log("existingCase", existingCase, answer);
      if (existingCase && existingCase.examsAnswer) {
        existingCase.examsAnswer.ecg = answer; // Add details to the case
      }
    },
    addSpirometryAnswer(
      state,
      action: PayloadAction<{ caseId: string; answer: string }>
    ) {
      const { caseId, answer } = action.payload;
      const existingCase = state.caseTests[caseId];
      console.log("existingCase", existingCase, answer);
      if (existingCase && existingCase.examsAnswer) {
        existingCase.examsAnswer.spirometry = answer; // Add details to the case
      }
    },
    addEchoAnswer(
      state,
      action: PayloadAction<{ caseId: string; answer: string }>
    ) {
      const { caseId, answer } = action.payload;
      const existingCase = state.caseTests[caseId];
      console.log("existingCase", existingCase, answer);
      if (existingCase && existingCase.examsAnswer) {
        existingCase.examsAnswer.echo = answer; // Add details to the case
      }
    },
    addCrpAnswer(
      state,
      action: PayloadAction<{ caseId: string; answer: string }>
    ) {
      const { caseId, answer } = action.payload;
      const existingCase = state.caseTests[caseId];
      console.log("existingCase", existingCase, answer);

      if (existingCase && existingCase.examsAnswer) {
        existingCase.examsAnswer.crp = answer; // Add details to the case
      }
    },
    addProBnpAnswer(
      state,
      action: PayloadAction<{ caseId: string; answer: string }>
    ) {
      const { caseId, answer } = action.payload;
      const existingCase = state.caseTests[caseId];
      if (existingCase && existingCase.examsAnswer) {
        existingCase.examsAnswer.proBnp = answer; // Add details to the case
      }
    },
    addPulseOxymetryAnswer(
      state,
      action: PayloadAction<{ caseId: string; answer: string }>
    ) {
      const { caseId, answer } = action.payload;
      const existingCase = state.caseTests[caseId];
      if (existingCase && existingCase.examsAnswer) {
        existingCase.examsAnswer.pulseOxymetry = answer; // Add details to the case
      }
    },
    addHbAnswer(
      state,
      action: PayloadAction<{ caseId: string; answer: string }>
    ) {
      const { caseId, answer } = action.payload;
      const existingCase = state.caseTests[caseId];
      if (existingCase && existingCase.examsAnswer) {
        existingCase.examsAnswer.hb = answer; // Add details to the case
      }
    },
    purgeCaseTests: (state) => {
      console.log("purgeCaseTests");
      state.caseTests = {}; // Reset the caseTests to an empty object
      state.isLoading = false; // Optionally reset isLoading
      state.error = null; // Optionally reset error
    }, 
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCaseTest.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addCaseTest.fulfilled, (state, action) => {
        const { caseId, exams } = action.payload;
        if (!state.caseTests[caseId]) {
          // If the case does not exist, add it
          state.caseTests[caseId] = {
            caseId,
            progress: 0,
            score: 0,
            exams: exams,
            examsAnswer: {
              lung: "",
              heart: "",
              ecg: "",
              crp: "",
              proBnp: "",
              firstChoice: "",
              pulseOxymetry: "",
              spirometry: "",
              echo: "",
              hb: "",
            },
          };
        } else {
          // If the case already exists, update it
          state.caseTests[caseId].exams = exams;
        }
        state.isLoading = false;
      })
      .addCase(addCaseTest.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Failed to add case test";
      });
    // ... other reducers ...
  },
});

export const addCaseTest = createAsyncThunk(
  "cases/addCaseTest",
  async ({ moduleId, caseId }: { moduleId: string; caseId: string }) => {
    const response = await fetch(
      `/modules/${moduleId}/cases/${caseId}/exams.json`
    );
    const exams = (await response.json()) as ExamsType;
    return { caseId, exams };
  }
);

//export const { addLungAnswer, addNewCaseTest } = caseTestsSlice.actions;
export const {
  addLungAnswer,
  addHeartAnswer,
  addFirstChoiceAnswer,
  addScore,
  addEcgAnswer,
  addCrpAnswer,
  addProBnpAnswer,
  addSpirometryAnswer,
  addPulseOxymetryAnswer,
  addEchoAnswer,
  addHbAnswer,
  purgeCaseTests
} = caseTestsSlice.actions;
export default caseTestsSlice.reducer;

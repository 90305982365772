import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Row, Col } from 'reactstrap';

interface CaseStepperProps {
  activeStep: number;
}

const CaseStepper: React.FC<CaseStepperProps> = ({ activeStep }) => {
  const getSteps = () => [
    "History",
    "Lung auscultation",
    "Heart auscultation",
    "Followup exams",
    "Finish",
  ];

  return (
    <div>
      <Row>
        <Col md={{ size: 12, order: 2, offset: 0 }}>
          <Row>
            <div className="content-choser-wrapper">
              <Box sx={{ width: '100%' }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {getSteps().map((label, index) => (
                    <Step key={label}>
                      <StepLabel
                        StepIconProps={{
                          sx: { color: activeStep === index ? '#1f5fc8' : 'inherit' },
                        }}
                        sx={{
                          '&.Mui-active': { color: '#1f5fc8', textTransform: 'uppercase', fontWeight: 500 },
                          '& .MuiStepIcon-root.Mui-active': { color: '#1f5fc8' },
                        }}
                      >
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </div>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CaseStepper;

import React from 'react';
import './Toolbar.css';
import 'font-awesome/css/font-awesome.min.css';


class Toolbar extends React.Component {
  state = {
    playing: false,
    icon: "play"
  }
  constructor(props) {
    super(props);
    this.toogleAudio = this.toogleAudio.bind(this);
  }

  toogleAudio() {
    console.log("toggle audio", this.props.playing)
    if (this.props.playing === false) {
      this.props.playAudio();
    }
    else {
      this.props.stopAudio();

    }
  }
  getIcon() {
    if (this.props.playing === false) {
      return "play"
    }
    else {
      return "pause"
    }
  }
  render() {
    return (
      <div className="audio-functionality-toolbar-wrapper">
        <div className="explanation-block">
          <div className="explanation-items">

          </div>
        </div>
        <div className="audio-functionality-toolbar">
          <a href="javascript:void(0)" className={"audio-function play"} onClick={this.toogleAudio}>
            
            <i className={"fa fa-"+ this.getIcon()} />
          </a>

        </div>
      </div>
    );
  }
}
export default Toolbar;

            /*<div className="explanation inspiration">Inspiration </div>
            <div className="explanation expiration">Expiration</div>*/
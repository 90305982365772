import React from 'react';

class TromsoStudy extends React.Component {
 
  render() {
    return (
    <div>
    <div className="content-block">
      <h3 className="upper">The Tromsø Study</h3>
      <p>
        The Tromsø Study is an epidemiological survey that started in 1974 with the main goal to determine the reasons for the high cardiovascular mortality in Tromsø, a city in North Norway with 75000 inhabitants. 
      </p>
      <p>
      The study has been periodically repeated with the last survey (7th) taking place in 2015-16.
      In Tromsø 7 all inhabitants in Tromsø aged 40 years or more were invited, and 65% (21083) attended. A random sample, approximately 9000,  was invited for an extended examination, including spirometry, ECG, and lung sound recordings. Echocardiography was carried out in a smaller sample with approximately 2300 participants. In the same sample heart sounds were recorded.
      </p>
      <p>  
      The cases in this application were selected among those with complete data from heart and lung sound recording, spirometry, ECG, echocardiography, the mMRC questionnaire, and certain blood tests, including NT-proBNP.
      </p>
    </div>
    </div>
    )
  }
}

export default TromsoStudy;

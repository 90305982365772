import React from 'react';
import { Row, Col } from 'reactstrap';
import BlueBigButton from '../components/blueBigButton/blueBigButton';

class Literature extends React.Component {
 
  render() {
    return (
    <div>
    <Row>
      <Col md={{ size: 12, order: 0, offset: 0 }}>

      <div className="content-block">
        <h3 className="upper">Literature</h3>
        <ul>
          <li style={{marginTop: "1rem"}}>Ponikowski P, Voors AA, Anker SD, Bueno H, Cleland JG, Coats AJ, Falk V, Gonzalez-Juanatey JR, Harjola VP, Jankowska EA, Jessup M, Linde C, Nihoyannopoulos P, Parissis JT, Pieske B, Riley JP, Rosano GM, Ruilope LM, Ruschitzka F, Rutten FH, van der Meer P (2016) 2016 ESC Guidelines for the diagnosis and treatment of acute and chronic heart failure: The Task Force for the diagnosis and treatment of acute and chronic heart failure of the European Society of Cardiology (ESC). Developed with the special contribution of the Heart Failure Association (HFA) of the ESC. Eur J Heart Fail 18: 891-975
          </li>
          <li style={{marginTop: "1rem"}}>
            Shub C. Echocardiography or auscultation? How to evaluate systolic murmurs. Can Fam Physician 2003; 49: 163-7. 
          </li>
          <li style={{marginTop: "1rem"}}>
            Fonseca C (2006) Diagnosis of heart failure in primary care. Heart Fail Rev 11: 95-107
          </li>
          <li style={{marginTop: "1rem"}}>
            Rutten FH, Cramer MJ, Lammers JW, Grobbee DE, Hoes AW (2006) Heart failure and chronic obstructive pulmonary disease: An ignored combination? Eur J Heart Fail 8: 706-711
          </li>
          <li style={{marginTop: "1rem"}}>
          Quanjer PH, Brazzale DJ, Boros PW, Pretto JJ. Implications of adopting the Global lungs initiative 2012 all-age reference equations for spirometry. Eur Respir J 2013;42: 1045-54.
          </li>
          <li style={{marginTop: "1rem"}}>
          Bohadana A, Izbicki G, Kraman SS. Fundamentals of lung auscultation. N Engl Med 2014; 370: 744-51. 
          </li>
          <li style={{marginTop: "1rem"}}>
          Nath AR, Capel LH. Inspiratory crackles - early and late. Thorax 1974;29: 223-7.
          </li>
          <li style={{marginTop: "1rem"}}>
          Aviles-Solis JC, Jacome C, Davidsen A, Einarsen R, Vanbelle S, Pasterkamp H, Melbye H. Prevalence and clinical associations of wheezes and crackles in the general population: the Tromso study. BMC Pulm Med. 2019;19:173.
          </li>
          <li style={{marginTop: "1rem"}}>
          Melbye H, Garcia-Marcos L, Brand P et al. Wheezes, Crackles and Rhonchi: Simplifying description of lung sounds increases the agreement on their classification: A study of 12 physicians' classification of lung sounds from video recordings. BMJ Open Respir Res 2016;3:e000136.
          </li>
        </ul>

      </div>
      </Col>
    </Row>
    <Row>
      <Col md= {{ size: 8, order: 0, offset: 2}}>
        <BlueBigButton link={'/'} text="Back to cases"/>
    </Col>
    </Row>
    </div>

    )
  }
}

export default Literature;

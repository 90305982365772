import React from 'react';

class LearningAim extends React.Component {
 
  render() {
    return (
    <div>
    <div className="content-block">
      <h3 className="upper">Learning aim</h3>
      <p>
        For users of this educational tool, 
        the aim could be to improve how they identify abnormal 
        sounds during heart and lung auscultation. 
        We also hope they will  get new knowledge on how abnormal 
        heart and lung sounds sounds are associated with diseases and ageing.
      </p>
    </div>
    <div className="content-block">
      <h3 className="upper">Learning through clinical cases</h3>
      <p>
        The cases are based on heart and lung sound recordings from 
        participants in the 7th Tromsø Study. Other findings related 
        to heart and lung diseases are, for each participant, added to 
        the sound recordings to build cases. The cases are presented as “patients” 
        visiting a general practitioner/family doctor for a check-up or due 
        to certain symptoms or diseases. Although illusory, since no doctor 
        visit took place in real life, we believe primary care doctors will 
        find the cases realistic. 
      </p>
    </div>
    </div>
    )
  }
}

export default LearningAim;

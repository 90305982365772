import { useEffect } from "react";

import "../modules.css";
import "./caseResult.css";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";

import MaleBackground from "../../assets/images/male11-nobackground.jpg";
import FemaleBackground from "../../assets/images/female5.jpg";
import CaseStepper from "../../components/caseStepper/caseStepper";
import BlueBigButton from "../../components/blueBigButton/blueBigButton";
import RetakeBlock from "../../components/retakeBlock";
import { addScore } from "../../reducers/caseTestSlice";

type CaseDetailsParams = {
  moduleId: string;
  caseId: string;
};

const CaseResult = () => {
  const { moduleId, caseId } = useParams<CaseDetailsParams>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const specificCase = useAppSelector(
    (state) => state.cases.cases[caseId as string]
  );
  const caseRootPath = `/modules/${moduleId}/cases/${caseId}`;

  const caseTest = useAppSelector((state) =>
    caseId ? state.caseTests.caseTests[caseId] : undefined
  );

  const calculateScore = () => {
    let score = 0;
    if (caseTest?.exams.lung === caseTest?.examsAnswer.lung) {
      score++;
    }
    if (caseTest?.exams.heart === caseTest?.examsAnswer.heart) {
      score++;
    }
    if (followupTestResult()) {
      score++;
    }
    return score;
  };

  const checkCorrectFirstTest = () => {
    const tests: Array<string> = [];
    const addTest = (condition: boolean | undefined, name: string) => {
      if (condition) tests.push(name);
    };

    addTest(caseTest?.exams.crp.firstChoice, "CRP");
    addTest(caseTest?.exams.ecg.firstChoice, "ECG");
    addTest(caseTest?.exams.echo.firstChoice, "Echo");
    addTest(caseTest?.exams.hb.firstChoice, "Hemoglobin");
    addTest(caseTest?.exams.none, "No more tests");
    addTest(caseTest?.exams.proBnp.firstChoice, "NT-proBNP");
    addTest(caseTest?.exams.spirometry.firstChoice, "Spirometry");
    addTest(caseTest?.exams.pulseOxymetry.firstChoice, "Pulse oximetry");

    return tests.join(" and ");
  };

  useEffect(() => {
    if (caseId) {
      dispatch(addScore({ caseId, score: calculateScore() }));
    }
  }, [dispatch, caseId]);

  const followupTestResult = () => {
    if (
      caseTest?.examsAnswer.firstChoice === "No more tests" &&
      caseTest?.exams.none === true
    ) {
      return true;
    } else if (
      caseTest?.examsAnswer.firstChoice === "ECG" &&
      caseTest?.exams.ecg.firstChoice === true
    ) {
      return true;
    } else if (
      caseTest?.examsAnswer.firstChoice === "Spirometry" &&
      caseTest?.exams.spirometry.firstChoice === true
    ) {
      return true;
    } else if (
      caseTest?.examsAnswer.firstChoice === "CRP" &&
      caseTest?.exams.crp.firstChoice === true
    ) {
      return true;
    } else if (
      caseTest?.examsAnswer.firstChoice === "NT-proBNP" &&
      caseTest?.exams.proBnp.firstChoice === true
    ) {
      return true;
    } else if (
      caseTest?.examsAnswer.firstChoice === "Pulse oximetry" &&
      caseTest?.exams.pulseOxymetry.firstChoice === true
    ) {
      return true;
    } else if (
      caseTest?.examsAnswer.firstChoice === "Echocardiography" &&
      caseTest?.exams.echo.firstChoice === true
    ) {
      return true;
    } else if (
      caseTest?.examsAnswer.firstChoice === "Hemoglobin" &&
      caseTest?.exams.hb.firstChoice === true
    ) {
      return true;
    } else {
      return false;
    }
  };

  const renderBackground = (gender: string) => {
    if (gender === "female") {
      return (
        <div
          className="patient-image"
          style={{
            background:
              `url(${FemaleBackground})` + "no-repeat center center / contain",
          }}
        ></div>
      );
    } else if (gender === "male") {
      return (
        <div
          className="patient-image"
          style={{
            background:
              `url(${MaleBackground})` + "no-repeat center center / contain",
          }}
        ></div>
      );
    }
  };

  return (
    <div className="container">
      <div className="main-content">
        <CaseStepper activeStep={5} />
        <div className="content-container">
          <Row>
            <Col md={{ size: 3, order: 1, offset: 0 }}>
              <div className="patient-container">
                <div className="patient-content">
                  {renderBackground(specificCase.details!.gender)}
                </div>
              </div>
            </Col>
            <Col md={{ size: 9, order: 2, offset: 0 }}>
              <div className="patient-info">
                <ul>
                  <li>
                    <span className="marker">{specificCase.name}</span>
                  </li>
                  <li>
                    <span className="marker">Description:</span>{" "}
                    {specificCase.details!.title}
                  </li>
                  <li>
                    <span className="marker">History:</span>{" "}
                    {specificCase.details!.description}
                  </li>
                  <br></br>
                  <li>
                    <span className="marker">
                      Total Score: {`${calculateScore()}/3`}
                    </span>
                  </li>
                  <li>
                    <span className="marker">Approved as first test:</span>
                    {" " + checkCorrectFirstTest()}
                  </li>
                  <li>
                    <span className="marker">Case summary:</span>{" "}
                    {specificCase.details!.comment}
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
        <Row>
          <Col md={{ size: 3, order: 1, offset: 2 }}>
            <RetakeBlock
              onClick={() => navigate(`${caseRootPath}/lung_auscultation`)}
              testType="lung"
              caseResult={caseTest?.exams.lung === caseTest?.examsAnswer.lung}
              buttonText="Listen again"
            />
          </Col>
          <Col md={{ size: 3, order: 2 }}>
            <RetakeBlock
              onClick={() => navigate(`${caseRootPath}/heart_auscultation`)}
              testType="heart"
              caseResult={caseTest?.exams.heart === caseTest?.examsAnswer.heart}
              buttonText="Listen again"
            />
          </Col>
          <Col md={{ size: 3, order: 3 }}>
            <RetakeBlock
              onClick={() => navigate(`${caseRootPath}/followup`)}
              testType="followup"
              caseResult={followupTestResult()}
              buttonText="New tests"
            />
          </Col>
        </Row>
        <Row></Row>
        <Row>
          <Col md={{ size: 3, order: 0, offset: 5 }}>
            <div>
              <div className="button-block case-result-button">
                <BlueBigButton link={"/"} text="Back to all cases" />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CaseResult;

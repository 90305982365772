import "../../App.css";
import { useAppDispatch } from "../../hooks";
import { CaseType } from "../../models/case";
import { CaseTestType } from "../../models/caseTest";
import { addEcgAnswer } from "../../reducers/caseTestSlice";
import "./ecg.css";
import { Row, Col } from "reactstrap";
import FormTestHorizontal from "../formTestHorizontal/formTestHorizontal";
import BravoBlock from "../bravoBlock/bravoBlock";

type EcgProps = {
  specificCase: CaseType;
  caseTest: CaseTestType;
  onClick: () => void;
};

const Ecg = (props: EcgProps) => {
  console.log("props in ecg", props);
  const classes = ["normal", "abnormal"];
  const dispatch = useAppDispatch();

  const submitEcgAnswer = (answer: string) => {
    console.log("dispatching answer", answer);
    dispatch(addEcgAnswer({ caseId: props.specificCase.id, answer }));
  };

  const testOrResult = () => {
    // ... other code ...

    if (props.caseTest.examsAnswer.ecg === "") {
      return (
        <FormTestHorizontal
          onClick={(answer: string) => submitEcgAnswer(answer)}
          classes={classes}
          question={"Results"}
        />
      );
    } else {
      return (
        <Row>
          <Col md={{ size: 6, order: 1, offset: 0 }}>
            <div className="bravo-block-tets">
              <BravoBlock
                correctAnswer={props.caseTest.exams.ecg.answer}
                userAnswer={props.caseTest.examsAnswer.ecg}
                buttonType={"onclick"}
                onClick={props.onClick}
              />
            </div>
          </Col>
          <Col md={{ size: 4, order: 5, offset: 0 }}>
            <div className="result-description">
              <div className="header-one-markup">Description</div>
              <p>{props.specificCase.details!.ecg.description}</p>
            </div>
          </Col>
        </Row>
      );
    }
  };

  return (
    <div className="ecg-container">
      <Row>
        <Col md={{ size: 1, order: 0, offset: 0 }}>
          <div className="header-text">
            <p>ECG</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={{ size: 12, order: 1, offset: 0 }}>
          <div >
            <img
              className="image"
              src={props.specificCase.details!.ecg.imagePath}
              alt="Image missing"
            />
          </div>
        </Col>
      </Row>
      {testOrResult()}
    </div>
  );
};

export default Ecg;

import "./badge.css";
import { Link } from "react-router-dom";

type BadgeProps = {
  link: {
    pathname: string;
  };
  title: string;
  explanation: string;
};

function Badge({ link, title, explanation }: BadgeProps) {
  return (
    <Link to={link} className="profile-badge info">
      <div className="icon-block">
        <div className="icon-inner" />
      </div>
      <div className="text-content">
        <div className="text-inner">
          <h3>{title}</h3>
          <div className="explanataion-block">{explanation}</div>
        </div>
        <div className="large-percentage-block">100%</div>
      </div>
      <div className="profile-progress">
        <div className="real-profile-progress"></div>
      </div>
    </Link>
  );
}

export default Badge;

import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();

  // Log the error to the console
  console.error(error);

  // Type guard to check if error is an instance of Error
  const errorMessage = error instanceof Error ? error.message : "Unknown error";

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{errorMessage}</i>
      </p>
    </div>
  );
}

import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";

// Create a styled button component
const BootstrapButton = styled(Button)<ButtonProps>(() => ({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#3131DA",
  borderColor: "#3131DA",
  borderRadius: 0,
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    backgroundColor: "#3131DA",
    borderColor: "#3131DA",
    color: "rgba(255,255,255,1)",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#3131DA",
    borderColor: "#3131DA",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(255,255,255,1)",
  },
}));

interface BlueBigButtonProps {
  text: string;
  link?: string;
  onClick?: () => void; // Optional onClick prop
}

import { useNavigate } from "react-router-dom";
// Import your Redux action, if necessary

export default function BlueBigButton(props: BlueBigButtonProps) {
  const navigate = useNavigate();

  const handleClick = (event: { preventDefault: () => void }) => {
    event.preventDefault(); // Prevent default anchor behavior

    // Optional: Dispatch a Redux action if needed
    // dispatch(yourReduxAction());

    if (props.onClick) {
      props.onClick();
    } else if (props.link) {
      // If no onClick prop, navigate to the provided link
      navigate(props.link);
    }
  };

  return (
    <BootstrapButton
      href={props.link}
      fullWidth
      variant="contained"
      disableRipple
      onClick={handleClick}
    >
      {props.text}
    </BootstrapButton>
  );
}

import React from 'react';
import { Row, Col } from 'reactstrap';
import AudioPlayer from '../AudioPlayer/AudioPlayer';


import systolicImage from '../assets/images/Intro_systolic_1.png';
import systolicAudio from '../assets/images/Intro_systolic_1.wav';
import diastolicImage from '../assets/images/Intro_diastolic_3.png';
import diastolicAudio from '../assets/images/Intro_diastolic_3.wav';

class HeartSound extends React.Component {
 
  render(){
    return (
    <div>
    <Row>
    <Col md={{ size: 12, order: 1, offset: 0 }}>

      <div className="content-block">
        <h3 className="upper">Heart sounds</h3>
        <p><strong>THE FOLLOWING HEART SOUNDS WILL BE PRESENTED:</strong></p>
        <ul>
          <li>
          Normal heart sounds
          </li>
          <li>
          Systolic murmurs
          </li>
          <li>
          Diastolic murmurs
          </li>
        </ul>

        <p>
          The murmurs are subdivided by amplitude (distinct/faint)
          The relation between a murmur and the second heart sound might in some cases be commented on, in particular when the second sound is inaudible 
          The recording site will be shown
        </p>
      </div>
    </Col>
    </Row>
    <Row>
      <Col md={{ size: 12, order: 0, offset: 0 }}>

      <div className="content-block">
        <h3 className="upper">Heart sounds - examples with spectrograms</h3>

      </div>
      </Col>
      <div className="content-block">

        <Col md={{ size: 12, order: 1, offset: 0 }}>
          <p><strong>Distinct systolic murmur over the aortic valve, clearly separated from second heart sound</strong></p>
            <AudioPlayer audioPath={systolicAudio}
                        spectrogramPath={systolicImage}/>
        </Col>
      </div>
    </Row>
    <Row>

      <div className="content-block">
        <Col md={{ size: 12, order: 1, offset: 0 }}>
          <p><strong>Diastolic murmur, over lower left sternum</strong></p>
            <AudioPlayer audioPath={diastolicAudio}
                            spectrogramPath={diastolicImage}/>
        </Col>
      </div>
    </Row>
    </div>
    )
  }
}

export default HeartSound;

import React from "react";

import "../../App.css";
//import './CRP.css';
import "./hb.css";
import { Row, Col } from "reactstrap";

import { CaseType } from "../../models/case";
import { CaseTestType } from "../../models/caseTest";
import { useAppDispatch } from "../../hooks";
import FormTestVertical from "../formTestVertical/formTestVertical";
import BravoBlock from "../bravoBlock/bravoBlock";
import { addHbAnswer } from "../../reducers/caseTestSlice";

type HbProps = {
  specificCase: CaseType;
  caseTest: CaseTestType;
  onClick: () => void;
};

const Hb = (props: HbProps) => {
  const classes = ["lower than normal", "normal", "higher than normal"];
  const dispatch = useAppDispatch();

  const submitHbAnswer = (answer: string) => {
    console.log("dispatching answer", answer);
    dispatch(addHbAnswer({ caseId: props.specificCase.id, answer }));
  };

  const testOrResult = () => {
    if (props.caseTest.examsAnswer.hb === "") {
      //console.log("rendering listenerTest", this.state.testState)

      return (
        <FormTestVertical
          onClick={(answer: string) => submitHbAnswer(answer)}
          classes={classes}
          question={"Results"}
        />
      );
    } else {
      //console.log("rendering userAnswered", this.state.testState)
      return (
        <Row>
          <Col md={{ size: 5, order: 1, offset: 0 }}>
            <div className="bravo-block-tets">
              <BravoBlock
                correctAnswer={props.caseTest.exams.hb.answer}
                userAnswer={props.caseTest.examsAnswer.hb}
                buttonType={"onclick"}
                onClick={props.onClick}
              ></BravoBlock>
            </div>
          </Col>
          <Col md={{ size: 5, order: 2, offset: 0 }}>
            <div className="hemoglobin-result-container">
              <div className="hemoglobin-reference-image"></div>
            </div>
          </Col>
        </Row>
      );
    }
  };

  return (
    <div className="crp-container">
      <Row>
        <Col md={{ size: 1, order: 0, offset: 0 }}>
          <div className="header-text">
            <p>Hemoglobin</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={{ size: 12, order: 1, offset: 0 }}>
          <div className="followup-text-block">
            <div className="description">
              <p>Patients hemoglobin shows:</p>
            </div>
            <div className="value">
              <p>{`  ${props.specificCase.details!.hb.value} g/dl`}</p>
            </div>
          </div>
        </Col>
      </Row>

      {testOrResult()}
    </div>
  );
};
/*class Hb extends React.Component {
  state = {
    testState: 'testState',
    correctAnswer: this.props.case.hemoglobin_test,
    userAnswer: '',
    classes: ['lower than normal', 'normal', 'higher than normal',]
  }
  constructor(props) {
    super(props);
    this.exitClick = this.exitClick.bind(this);
    this.changeTestState = this.changeTestState.bind(this);
  }

  exitClick() {
    this.props.setFollowupState('followup');
  }

  changeTestState(testState){
    //console.log("changing test state", testState)
    this.setState({ userAnswer: testState })
    this.setState({ testState: 'userAnswered' })
  }


  testOrResult() {
    //console.log(this.state.testState)
    //console.log("in test or result props", this.props)
    if (this.state.testState === 'testState') {
      //console.log("rendering listenerTest", this.state.testState)

      return(
        <FormTestVertical 
                onClick={this.changeTestState} 
                classes={this.state.classes}
                question={"Select the range corresponsing to patient's values:"} >
        </FormTestVertical>)
    }

    else if (this.state.testState === 'userAnswered') {
      //console.log("rendering userAnswered", this.state.testState)  
      return(
            <Row>
              <Col md={{ size: 5, order:1, offset:0 }}>
                  <div className="bravo-block-tets">
                <BravoBlock 
                   correctAnswer={this.state.correctAnswer}
                   userAnswer={this.state.userAnswer}
                   buttonType={'onclick'}
                   onClick={this.exitClick}>
                </BravoBlock>
                </div>
              </Col>
              <Col md={{ size: 5, order:2, offset:1 }}>

                <div className="hemoglobin-result-container">
                  <div className="hemoglobin-reference-image"></div>
                </div>
              </Col>
            </Row>
            )
    }

  }

  render() {
    return (
      <div className="crp-container">
        <Row>
          <Col md={{ size: 1, order: 0, offset: 0 }}>
            <div className="header-text">
              <p>Hemoglobin</p>
            </div>
          </Col>

        </Row>
        <Row>
        <Col md={{ size: 12, order: 1, offset: 0 }}>
          <div className="followup-text-block">
            <div className="description">
              <p>Patients hemoglobin shows:</p>
            </div>
            <div className="value">
              <p> {"  " +this.props.case.hemoglobin} g/dl</p>
            </div>
            

          </div>
        </Col>
        </Row>

            {this.testOrResult()}

      </div>
    );
  }
}*/
/* class ECG extends React.Component {
  render() {
    return (
      <div className="ECG-container">
      </div>
    );
  }
} */

export default Hb;

import "./index.css";
type retakeBlockProps = {
  onClick: () => void;
  testType: string;
  caseResult: boolean;
  buttonText: string;
};

const RetakeBlock = (props: retakeBlockProps) => {
  const className = `${props.testType}-image`;

  const success = () => {
    return (
      <div className="success-icon">
        <i className="fa fa-check"></i>
      </div>
    );
  };

  const failure = () => {
    return (
      <div className="failure-icon">
        <i className="fa fa-times"></i>
      </div>
    );
  }

  return (
    <div className="retake-block">
      <div className="image-block">
        <div className={className}></div>
      </div>
      {props.caseResult ? success() : failure()}
      <div className="retake-button" onClick={() => props.onClick()}>
        <div className="text">{props.buttonText}</div>
      </div>
    </div>
  );
};

export default RetakeBlock;

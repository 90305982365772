import "./bravoBlock.css";
import { useNavigate } from "react-router-dom";

type BravoBlockProps = {
  userAnswer: string;
  correctAnswer: string;
  buttonType: string;
  link?: string;
  onClick?: () => void;
};

const BravoBlock = (props: BravoBlockProps) => {
  const result = props.userAnswer === props.correctAnswer ? "success" : "error";
  console.log(props.userAnswer, props.correctAnswer);
  const navigate = useNavigate(); // Moved to the top level of the component

  const handleClick = () => {
    if (props.buttonType === "link" && props.link) {
      navigate(props.link);
    } else if (props.buttonType === "onclick" && props.onClick) {
      props.onClick();
    }
  };

  const linkOrEventButton = () => {
    return (
      <a className="continue-button" onClick={handleClick}>
        Continue
      </a>
    );
  };

  return (
    <div>
      <div className="listener-test-lung">
        <div className={"bravo-block " + result + " active"}>
          <div className="content">
            <div className="content-icon"></div>
            <div className="content-text">
              <span className="large"></span>
            </div>
            <div className="content-message">
              Correct Answer: <br></br>
              <strong>{props.correctAnswer}</strong>
            </div>
          </div>
          {linkOrEventButton()}
        </div>
      </div>
    </div>
  );
};
export default BravoBlock;

import { Row, Col } from "reactstrap";
import Badge from "../components/badge/badge";
import "./modules.css";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useEffect } from "react";
import { fetchModules } from "../reducers/moduleSlice";


const Modules = () => {
  const dispatch = useAppDispatch();
  const { modules } = useAppSelector(state => state.modules);

  useEffect(() => {
    dispatch(fetchModules());
  }, [dispatch]);


  //const modules = useLoaderData() as ModuleType[];
  const createBadges = () => {
    const badges = [];
    for (let i = 0; i < modules.length; i += 1) {
      const intro = {
        pathname: "/intro",
      };
      if (i == 0) {
        badges.push(
          <Badge
            link={intro}
            key={modules[i].id}
            title={modules[i].title}
            explanation={modules[i].description}
          />
        );
      } else {
        const newTo = {
          pathname: "/modules/" + modules[i].id,
        };
        badges.push(
          <Badge
            link={newTo}
            key={modules[i].id}
            title={modules[i].title}
            explanation={modules[i].description}
          />
        );
      }
    }
    return badges;
  };

  return (
    <div>
      <div className="container">
        <div className="main-content">
          <Row>
            <Col md={{ size: 8, order: 2, offset: 2 }}>
              <h3> Welcome to heart and lung sounds from the Tromsø Study </h3>
              <p>Read the intro and select a group of cases to start. Headset or loudspeaker should be used.</p>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 10, order: 2, offset: 2 }}>
              <div className="profile-badges-container">{createBadges()}</div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
export default Modules;

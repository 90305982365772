import "../../App.css";
import "./FollowupTest.css";

type labelType = {
  label: string;
  opacity: string;
};

type FollowupTestProps = {
  dataKey: string; // Added this prop
  data: labelType;
  onClick: (key: string, label: string) => void;
};

const FollowupTest = ({ dataKey, data, onClick }: FollowupTestProps) => {
  const handleClick = () => {
    onClick(dataKey, data.label); // Updated to pass the correct parameters
  };

  return (
    <div
      className="test-block"
      onClick={handleClick}
      style={{ opacity: data.opacity }}
    >
      <div className="text-field">
        <p>{data.label}</p>
      </div>
    </div>
  );
};

export default FollowupTest;

import { useState } from "react";
import Popup from "reactjs-popup";
import "./noTestsPopup.css";
import BlueBigButton from "../blueBigButton/blueBigButton";
import { CaseTestType } from "../../models/caseTest";
import { useNavigate } from "react-router-dom";

type NoTestsPopupProps = {
  caseTest: CaseTestType;
  closePopup: () => void;
  link: string;
  testCount: number;
};

const NoTestsPopup = (props: NoTestsPopupProps) => {
  const [open, setOpen] = useState<boolean>(true);
  const navigate = useNavigate();
  const closeModal = () => {
    console.log("close modal button clicked");
    setOpen(false);
    props.closePopup();
  };

  const renderPopupContent = () => {
    let modalText = "";
    let modalSubText = "";
    let redirect = "";
    if (props.testCount > 1) {
      setOpen(false);
      navigate(props.link);
    }

    if (
      props.caseTest.examsAnswer.firstChoice === "No more tests" &&
      props.caseTest.exams.none === true
    ) {
      modalText = "Good answer";
      redirect = props.link;
    } else if (
      props.caseTest.examsAnswer.firstChoice === "No more tests" &&
      props.caseTest.exams.none === false
    ) {
      modalText = "Not acceptable answer";
      modalSubText = "Choose another test";
    } else if (props.caseTest.examsAnswer.firstChoice !== "No more tests") {
      setOpen(false);
      navigate(props.link);
    }
    return (
      <div className="no-test-popup">
        <div className="text-header">
          <p>{modalText}</p>
        </div>
        <div className="text-subtext">
          <p>{modalSubText}</p>
        </div>
        <div className="button-block">
          <div className="button">
            {redirect === "" ? (
              <BlueBigButton onClick={closeModal} text="Continue" />
            ) : (
              <BlueBigButton link={props.link} text="Continue" />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Popup
      //modal
      open={open}
      closeOnDocumentClick
      //onClose={}
      contentStyle={{
        borderColor: "rgb(49, 49, 218)",
        borderWidth: "3px",
        width: "40%",
      }}
    >
      {renderPopupContent()}
    </Popup>
  );
};

export default NoTestsPopup;

import React from 'react';
import './PageChooser.css';


class PageChooser extends React.Component {
  constructor(props){
    super(props)
    //console.log("props in radio_buttons", this.props)
  }

  checkActive(page) {
  	if (this.props.page == page){
  		return "active"
  	}
  	else {
  		return "disabled"
  	}
  }

	render () {
		return (
			<div className="content-choser">
			<div class="test-one"></div>
			  <ul>

			  	<li className={this.checkActive("Learning aim")}>
			  	  <a href="#"  onClick={() => this.props.setPage("Learning aim")}>Learning aim</a>
			  	</li>
			  	<li className={this.checkActive("Interactivity")}>
			  	  <a href="#"  onClick={() => this.props.setPage("Interactivity")}>Interactivity</a>
			  	</li>
			  	<li className={this.checkActive("Sound recording")}>
			  	  <a href="#"  onClick={() => this.props.setPage("Sound recording")}>Sound recording</a>
			  	</li>
			  	<li className={this.checkActive("Lung sound")}>
			  	  <a href="#"  onClick={() => this.props.setPage("Lung sound")}>Lung sounds</a>
			  	</li>
			  	<li className={this.checkActive("Heart sound")}>
			  	  <a href="#"  onClick={() => this.props.setPage("Heart sound")}>Heart sounds</a>
			  	</li>
			  	<li className={this.checkActive("Symptoms")}>
			  	  <a href="#"  onClick={() => this.props.setPage("Symptoms")}>Symptoms</a>
			  	</li>
			  	<li className={this.checkActive("SpirometryPulseOximetry")}>
			  	  <a href="#"  onClick={() => this.props.setPage("SpirometryPulseOximetry")}>SPIROMETRY AND PULSE OXIMETRY</a>
			  	</li>
			  	<li className={this.checkActive("Ecg echo")}>
			  	  <a href="#"  onClick={() => this.props.setPage("Ecg echo")}>ECG AND ECHOCARDIOGRAPHY</a>
			  	</li>
			  	<li className={this.checkActive("Blood tests")}>
			  	  <a href="#"  onClick={() => this.props.setPage("Blood tests")}>Blood tests</a>
			  	</li>
			  	<li className={this.checkActive("Tromso study")}>
			  	  <a href="#"  onClick={() => this.props.setPage("Tromso study")}>The Tromsø Study</a>
			  	</li>
			  	<li className={this.checkActive("Intro")}>
			  	  <a href="#"  onClick={() => this.props.setPage("Intro")}>Team</a>
			  	</li>
			  	<li className={this.checkActive("Privacy and ethics")}>
			  	  <a href="#"  onClick={() => this.props.setPage("Privacy and ethics")}>Privacy and ethics</a>
			  	</li>
			  	<li className={this.checkActive("Literature")}>
			  	  <a href="#"  onClick={() => this.props.setPage("Literature")}>Literature</a>
			  	</li>
			  </ul>
		</div>)
	}

}
export default PageChooser;

import React from "react";
import "./caseBadge.css";
import { Link } from "react-router-dom";
import { CaseTestType } from "../../models/caseTest";
/* <Routes path='/module' component={Module}/> // renders <About/> */

function progressBadge(caseTest: { progress: number; score: number }) {
  if (caseTest == null || caseTest.progress == null) {
    return "info";
  }

  if (caseTest.progress == 0) {
    return "info";
  } else if (caseTest.progress < 3) {
    return "info";
  } else {
    if (caseTest.score >= 2) {
      return "success";
    } else {
      return "error";
    }
  }
}

type BadgeProps = {
  link: {
    pathname: string;
  };
  title: string;
  explanation: string;
  caseTest: CaseTestType;
};

function CaseBadge({ link, title, explanation, caseTest }: BadgeProps) {

  const cacluatePercentageProgress = () => {
    if (caseTest == null || caseTest.progress == null) {
      return "10%";
    }
    return `${(caseTest.progress / 3)*100}%`;
  }
  return (
    <Link to={link} className={"profile-badge " + progressBadge(caseTest)}>
      <div className="icon-block">
        <div className="icon-inner" />
      </div>
      <div className="text-content">
        <div className="text-inner">
          <h3>{title}</h3>
          <div className="explanataion-block">{explanation}</div>
        </div>
      </div>
      <div className="profile-progress">
        <div className="real-profile-progress" style={{width: cacluatePercentageProgress()}}></div>
      </div>
    </Link>
  );
}

export default CaseBadge;

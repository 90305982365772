import React from "react";

import "../../App.css";
import "./proBnp.css";
import { CaseType } from "../../models/case";
import { CaseTestType } from "../../models/caseTest";
import { useAppDispatch } from "../../hooks";
import { Col, Row } from "reactstrap";
import FormTestVertical from "../formTestVertical/formTestVertical";
import { addProBnpAnswer } from "../../reducers/caseTestSlice";
import BravoBlock from "../bravoBlock/bravoBlock";

type ProBnpProps = {
  specificCase: CaseType;
  caseTest: CaseTestType;
  onClick: () => void;
};

const ProBnp = (props: ProBnpProps) => {
  const classes = ["normal", "elevated"];
  const dispatch = useAppDispatch();
  console.log("in probnp props", props);
  const submitProBnpAnswer = (answer: string) => {
    console.log("dispatching answer", answer);
    dispatch(addProBnpAnswer({ caseId: props.specificCase.id, answer }));
  };

  const testOrResult = () => {
    if (props.caseTest.examsAnswer.proBnp === "") {
      return (
        <FormTestVertical
          onClick={(answer: string) => submitProBnpAnswer(answer)}
          classes={classes}
          question={"Results"}
        />
      );
    } else {
      return (
        <Row>
          <Col md={{ size: 6, order: 1, offset: 0 }}>
            <div className="bravo-block-tets">
              <BravoBlock
                correctAnswer={props.caseTest.exams.proBnp.answer}
                userAnswer={props.caseTest.examsAnswer.proBnp}
                buttonType={"onclick"}
                onClick={props.onClick}
              />
            </div>
          </Col>
          <Col md={{ size: 6, order: 2, offset: 0 }}>
            <div className="probnp-result-container">
              <div className="probnp-reference-image"></div>
            </div>
          </Col>
        </Row>
      );
    }
  };
  return (
    <div className="probnp-container">
      <Row>
        <Col md={{ size: 4, order: 0, offset: 0 }}>
          <div className="header-text">
            <p>NT-proBNP</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={{ size: 12, order: 1, offset: 0 }}>
          <div className="followup-text-block">
            <div className="description">
              <p>Patients NT-proBNP shows:</p>
            </div>
            <div className="value">
              <p>{"  " + props.specificCase.details!.proBnp.value} pg/ml</p>
            </div>
          </div>
        </Col>
      </Row>
      {testOrResult()}
    </div>
  );
};

export default ProBnp;

/*class ProBNP extends React.Component {
  state = {
    testState: 'testState',
    correctAnswer: this.props.case.probnp_test,
    userAnswer: '',
    classes: ['normal', 'elevated']
  }
  constructor(props) {
    super(props);
    this.exitClick = this.exitClick.bind(this);
    this.changeTestState = this.changeTestState.bind(this);
  }

  exitClick() {
    this.props.setFollowupState('followup');
  }

  changeTestState(testState){
    //console.log("changing test state", testState)
    this.setState({ userAnswer: testState })
    this.setState({ testState: 'userAnswered' })
  }


  testOrResult() {
    //console.log(this.state.testState)
    //console.log("in test or result props", this.props)
    if (this.state.testState === 'testState') {
      //console.log("rendering listenerTest", this.state.testState)

      return(
        <FormTestVertical 
                onClick={this.changeTestState} 
                classes={this.state.classes}
                question={"Select the range corresponsing to patient's values:"} >
        </FormTestVertical>)
    }
    else if (this.state.testState === 'userAnswered') {
      //console.log("rendering userAnswered", this.state.testState)  
      return(
            <Row>
              <Col md={{ size: 5, order:1, offset:0 }}>
                <div className="bravo-block-tets">
                <BravoBlock 
                   correctAnswer={this.state.correctAnswer}
                   userAnswer={this.state.userAnswer}
                   buttonType={'onclick'}
                   onClick={this.exitClick}>
                </BravoBlock>
                </div>
              </Col>
              <Col md={{ size: 6, order:2, offset:0 }}>
                <div className="probnp-result-container">
                  <div className="probnp-reference-image"></div>
                </div>
              </Col>
            </Row>
            )
    }

  }

  render() {
    return (
      <div className="probnp-container">
        <Row>
          <Col md={{ size: 4, order: 0, offset: 0 }}>
            <div className="header-text">
              <p>NT-proBNP</p>
            </div>
          </Col>


        </Row>
        <Row>
        <Col md={{ size: 12, order: 1, offset: 0 }}>
          <div className="followup-text-block">
            <div className="description">
              <p>Patients NT-proBNP shows:</p>
            </div>
            <div className="value">
               <p>{"  " +this.props.case.pro_bnp} pg/ml</p>
            </div>

          </div>
        </Col>
         </Row>
            {this.testOrResult()}

       

      </div>
    );
  }
}*/
/* class ECG extends React.Component {
  render() {
    return (
      <div className="ECG-container">
      </div>
    );
  }
} */

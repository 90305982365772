import React from 'react';
import { Row, Col } from 'reactstrap';

class SpirometryPulseOximetry extends React.Component {
 
  render() {
    return (
    <div>
    <Row>
      <Col md={{ size: 12, order: 0, offset: 0 }}>

      <div className="content-block">
        <h3 className="upper">Spirometry and pulse oximetry</h3>
        <p>
        Spirometry was performed using SensorMedics Vmax 20c Encore (VIASYS Healthcare Respiratory Technologies, Yorba Linda, CA, USA). 
        The Global Lung Function Initiative (GLI 2012) reference values were applied. 
        Oxygen saturation (Sp02) was measured with a pulse oximeter Onyx II model 9550 
        (Nonin Medical, Inc., Plymouth, MN, USA) The highest value after three measurements was registered.
        </p>
      </div>
      </Col>

    </Row>
    <Row>
      <Col md={{ size: 4, order: 0, offset: 0 }}>

      <div className="spir-puls-image-container">
        <div className="spirometry-image">
      </div>
    </div>
    </Col>
      <Col md={{ size: 4, order: 0, offset: 0 }}>

      <div className="spir-puls-image-container">
        <div className="oximetry-image">
      </div>
    </div>
    </Col>
    </Row>
    <Row>
      <div className="content-block">
        <h3 className="upper">We present volume time curves, flow-volume curves and Z-scores. </h3>
        <p>
          The Z-score is the number of standard deviations away from predicted value (based on age, gender and height). Lower limit of normal (LLN), has a Z-score of -1.64, which is also the 5% percentile.
        </p>
        <p>

          A hint when reading the volume-time curves:
          in some cases, hesitation when starting the 
          forced expiratory maneuvre can be a source of bias. 
          Automatically, the Vmax spirometer estimates the starting point as if the blow had started promptly. This adjustment can be observed in some of the volume-time-curves
        </p>
      </div>
    </Row>   
    </div>

    )
  }
}

export default SpirometryPulseOximetry;

/*    <Row>
      <div className="content-block">
        <h3 className="upper">We present volume-time curves and flow-volume curves.</h3>
        <p>

          A hint when reading the volume-time curves:
          In some cases, hesitation when starting the 
          forced expiratory maneuve can be a source of bias. 
          Automatically, the Vmax spirometer estimates the starting point as if the blow had started promptly. This adjustment can be observed in some of the volume-time-curves
        </p>
      </div>
    </Row>*/

import { useEffect } from "react";

import "../../App.css";
import "./heartAuscultation.css";
import { Row, Col } from "reactstrap";

import heartImage1 from "../../assets/images/heart_1.png";
import heartImage2 from "../../assets/images/heart_2.png";
import heartImage3 from "../../assets/images/heart_3.png";
import heartImage4 from "../../assets/images/heart_4.png";

import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import CaseStepper from "../../components/caseStepper/caseStepper";
import AudioPlayer from "../../AudioPlayer/AudioPlayer";
import AudioTest from "../../components/listenerTest/audioTest";
import { addHeartAnswer, addCaseTest } from "../../reducers/caseTestSlice"; // Import the action creator
import BravoBlock from "../../components/bravoBlock/bravoBlock";

type HeartAuscultationProps = {
  moduleId: string;
  caseId: string;
};

const HeartAuscultation = () => {
  const { moduleId, caseId } = useParams<HeartAuscultationProps>();
  const labels: Array<string> = [
    "no murmur",
    "systolic murmur",
    "diastolic murmur",
    "systolic and diastolic",
  ];
  const specificCase = useAppSelector(
    (state) => state.cases.cases[caseId ?? ""]
  );

  const caseTest = useAppSelector((state) =>
    caseId ? state.caseTests.caseTests[caseId] : undefined
  );

  const dispatch = useAppDispatch();

  const renderPositionImage = (position: string) => {
    if (position === "1") {
      return heartImage1;
    }
    if (position === "2") {
      return heartImage2;
    }
    if (position === "3") {
      return heartImage3;
    }
    if (position === "4") {
      return heartImage4;
    }
  };

  useEffect(() => {
    if (caseId != "" && caseId != null && moduleId !== undefined) {
      dispatch(addCaseTest({ caseId, moduleId }));
    }
  }, [dispatch, moduleId, caseId]);

  if (
    specificCase === undefined ||
    specificCase.details === undefined ||
    caseTest === undefined
  ) {
    return <div>Loading...</div>;
  }

  const submitAnswer = (answer: string) => {
    if (caseId) {
      dispatch(addHeartAnswer({ caseId, answer }));
    }
  };

  const caseTestBlock = () => {
    if (caseTest.examsAnswer.heart === "") {
      return (
        <AudioTest
          onClick={(answer) => submitAnswer(answer)}
          labels={labels}
          question={"How would you describe this heart sound?"}
        ></AudioTest>
      );
    } else if (specificCase.details !== undefined) {
      return (
        <Row>
          <Col md={{ size: 5, order: 1, offset: 3 }}>
            <BravoBlock
              correctAnswer={caseTest.exams.heart}
              userAnswer={caseTest.examsAnswer.heart}
              link={`/modules/${moduleId}/cases/${caseId}/before_followup`}
              buttonType="link"
            ></BravoBlock>
          </Col>
          <Col md={{ size: 3, order: 2, offset: 0 }}>
            <div className="result-description">
              <div className="header-one-markup">Description</div>
              <p>{specificCase.details.heart.description}</p>
            </div>
          </Col>
        </Row>
      );
    }
  };

  return (
    <div className="container">
      <Col className="main-content">
        <CaseStepper activeStep={1} />
        <div className="content-container">
          <Row>
            <Col md={{ size: 12, order: 0, offset: 3 }}>
              <div className="header-one-markup">Heart Auscultation</div>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 3, order: 1, offset: 0 }}>
              <div className="accordion-container">
                <div className="accordion-item">
                  <div className="accordion-trigger-block">
                    <div className="title no-highlightning">
                      Auscultation position:{" "}
                      {specificCase.details.heart.position}
                    </div>
                  </div>
                  <div className="accordion-content">
                    <div
                      className="inner-content back"
                      style={{
                        background:
                          `url(${renderPositionImage(
                            specificCase.details.heart.position
                          )})` + "no-repeat center center / contain",
                      }}
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col md={{ size: 9, order: 1, offset: 0 }}>
              {
                <AudioPlayer
                  audioPath={specificCase.details.heart.audioPath}
                  spectrogramPath={specificCase.details.heart.specPath}
                />
              }
            </Col>
          </Row>
        </div>
        <div className="test-container">{caseTestBlock()}</div>
      </Col>
    </div>
  );
};
export default HeartAuscultation;

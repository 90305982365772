import React from 'react';
import { Row, Col } from 'reactstrap';
import AudioPlayer from '../AudioPlayer/AudioPlayer';

import cracklesImage from '../assets/images/Intro_crackles_3.png';
import cracklesAudio from '../assets/images/Intro_crackles_3.wav'
import wheezesImage from '../assets/images/Intro_wheezes_2.png';
import wheezesAudio from  '../assets/images/Intro_wheezes_2.wav';


class LungSound extends React.Component {
 
  render() {
    return (
    <div>
    <Row>
    <Col md={{ size: 12, order: 1, offset: 0 }}>

      <div className="content-block">
        <h3 className="upper">Lung sounds</h3>
        <p><strong>The following lung sounds will be presented</strong></p>
        <ul>
          <li>
          Normal breath sounds
          </li>
          <li>
          Crackles
          </li>
          <li>
          Wheezes
          </li>
          <li>
          Pleural friction rubs
          </li>
        </ul>

        <p>
        Crackles and wheezes are subdivided by respiratory phase (inspiratory/expiratory)
        Inspiratory crackles may be categorized as early or late, but are not divided into fine and coarse, due to the difficulties in reaching agreement on this sound quality
        Wheezes may in some cases be categorized as high-pitched and low-pitched, rhonchi are regarded as low-pitched wheezes
        The recording site will be shown
        </p>
      </div>
    </Col>
    </Row>
    <Row>
      <Col md={{ size: 12, order: 0, offset: 0 }}>
        <div className="content-block">
          <h3 className="upper">Lung sounds - examples with spectrograms</h3>
        </div>
      </Col>
      <div className="content-block">
        <Col md={{ size: 12, order: 2, offset: 0 }}>
          <p><strong>Inspiratory crackles, over the right lung base</strong></p>
            <AudioPlayer audioPath={cracklesAudio}
                        spectrogramPath={cracklesImage}/>
      </Col>
      </div>
    </Row>
    <Row>
      <div className="content-block">
        <Col md={{ size: 12, order: 1, offset: 0 }}>
          <p><strong>Expiratory wheezes over left upper back</strong></p>
            <AudioPlayer audioPath={wheezesAudio}
                            spectrogramPath={wheezesImage}/>
        </Col>
      </div>
    </Row>
    </div>
    )
  }
}

export default LungSound;

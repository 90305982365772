import "../../App.css";
import "./echo.css";
import { Player } from "video-react";
import "../../../node_modules/video-react/dist/video-react.css";
import { useAppDispatch } from "../../hooks";
import { Col, Row } from "reactstrap";
import { CaseType } from "../../models/case";
import { CaseTestType } from "../../models/caseTest";
import FormTestHorizontal from "../formTestHorizontal/formTestHorizontal";
import { addEchoAnswer } from "../../reducers/caseTestSlice";
import BravoBlock from "../bravoBlock/bravoBlock";

type EchoProps = {
  specificCase: CaseType;
  caseTest: CaseTestType;
  onClick: () => void;
};

const Echo = (props: EchoProps) => {
  const classes = ["normal", "abnormal"];
  const dispatch = useAppDispatch();

  const submitEchoAnswer = (answer: string) => {
    dispatch(addEchoAnswer({ caseId: props.specificCase.id, answer }));
  };

  const testOrResult = () => {
    if (props.caseTest.examsAnswer.echo === "") {
      return (
        <FormTestHorizontal
          onClick={(answer: string) => submitEchoAnswer(answer)}
          classes={classes}
          question={"Results"}
        ></FormTestHorizontal>
      );
    } else {
      return (
        <Row>
          <Col md={{ size: 6, order: 1, offset: 0 }}>
            <div className="bravo-block-tets">
              <BravoBlock
                correctAnswer={props.caseTest.exams.echo.answer}
                userAnswer={props.caseTest.examsAnswer.echo}
                buttonType={"onclick"}
                onClick={props.onClick}
              ></BravoBlock>
            </div>
          </Col>
          <Col md={{ size: 4, order: 2, offset: 0 }}>
            <div className="result-description">
              <div className="header-one-markup">Description</div>
              <p>{props.specificCase.details!.echo.description}</p>
            </div>
          </Col>
        </Row>
      );
    }
  };

  const renderEchoVideo = () => {
    // Some case sadly only have images, not videos
    return (
      <div className="video-container">
        <Player playsInline src={props.specificCase.details!.echo.videoPath} />
      </div>
    );
  };

  return (
    <div className="echo-container">
      <Row>
        <Col md={{ size: 1, order: 0, offset: 0 }}>
          <div className="header-text">
            <p>Echocardiography</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={{ size: 8, order: 1, offset: 0 }}>{renderEchoVideo()}</Col>
      </Row>
      {testOrResult()}
    </div>
  );
};

/*class Echo extends React.Component {
  state = {
    testState: 'testState',
    correctAnswer: this.props.case.echo_test,
    userAnswer: '',
    classes: ['normal', 'abnormal'],
    echoPath: '',
  }
  constructor(props) {
    super(props);
    this.exitClick = this.exitClick.bind(this);
    this.changeTestState = this.changeTestState.bind(this);

  }

  exitClick() {
    this.props.setFollowupState('followup');
  }

  changeTestState(testState){
    //console.log("changing test state", testState)
    this.setState({ userAnswer: testState })
    this.setState({ testState: 'userAnswered' })
  }

  testOrResult() {
    //console.log(this.state.testState)
    //console.log("in test or result props", this.props)
    if (this.state.testState === 'testState') {
      //console.log("rendering listenerTest", this.state.testState)

      return(<FormTestHorizontal 
              onClick={this.changeTestState} 
              classes={this.state.classes}
              question={'Results'}>
        </FormTestHorizontal>)
    }
    else if (this.state.testState === 'userAnswered') {
      //console.log("rendering userAnswered", this.state.testState)  
      return(
            <Row>
              <Col md={{ size: 5, order:1, offset:0 }}>
                <div className="bravo-block-tets">

                <BravoBlock 
                   correctAnswer={this.state.correctAnswer}
                   userAnswer={this.state.userAnswer}
                   buttonType={'onclick'}
                   onClick={this.exitClick}>
                </BravoBlock>
                </div>
              </Col>
              <Col md={{ size: 3, order:2, offset:0 }}>
                <div className="result-description">
                  <div className="header-one-markup">Description</div>
                  <p>
                    {this.props.case.echo_description}
                  </p>
                </div>
              </Col>
            </Row>
            )
    }
  }

  async componentDidMount() {
    //console.log("props", this.props)
    const path =  '/api/halt_modules/' + this.props.case.halt_module_id + '/cases/' + this.props.case.id + '/echo_path.json';
    var strr = [];
    let config = {
      headers: await tokenizedHeader()
    }
    //console.log("path in ecg", path)
    await axios
      .get(path, config)
      .then(function(response){
        //console.log("response in echo", response)
        strr.push(APIConfig.url + response.data)
      })
      .catch(function(error){
        //console.log(error);
        strr.push(0)
      });
      this.setState({ echoPath: strr[0] })
      return strr[0]
      
  }
  videoOrImage() {
    if (this.props.case.name == "Case 1c"){
      return(<div className="echo-image-container">
             <img className="image" src={require("./assets/images/ECHO_pict_ic.JPG")} alt="Image missing" />
            </div>)

    }
    else if (this.props.case.name == "Case 1e"){
      return(<div className="echo-image-container">
             <img className="image" src={require("./assets/images/ECHO_pict_1e.JPG")} alt="Image missing" />
            </div>)

    }
    
    else if (this.props.case.name == "Case 2a") {
      return(<div className="echo-image-container">
             <img className="image" src={require("./assets/images/ECHO_pict_2a.JPG")} alt="Image missing" />
            </div>)     
    }
    else {
      return(<div className="video-container">
            <Player
              playsInline
              src={this.state.echoPath}
            />
            </div>)
    }
  }
  render() {
    //console.log("props in followup ecg", this.props);
    return (
      <div className="ecg-container">
        <Row>
          <Col md={{ size: 1, order: 0, offset: 0 }}>
            <div className="header-text">
              <p>Echocardiography</p>
            </div>
          </Col>

        </Row>
        <Row>
          <Col md={{ size: 8, order: 1, offset: 0 }}>
            
            {this.videoOrImage()}
          </Col>
        </Row>
            {this.testOrResult()}
      </div>
    );
  }
}*/
/* class ECG extends React.Component {
  render() {
    return (
      <div className="ECG-container">
      </div>
    );
  }
} */

export default Echo;

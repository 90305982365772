import { useState } from "react";
import Popup from "reactjs-popup";
import "./resetPopup.css";
import BlueBigButton from "../blueBigButton/blueBigButton";
import { useAppDispatch } from "../../hooks";
import { purgeCaseTests } from "../../reducers/caseTestSlice";

type resetPopupProps = {
  closePopup: () => void;
};

const resetPopup = (props: resetPopupProps) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(true);

  const closeModal = () => {
    props.closePopup();
    setOpen(false);
  }
  const deleteProgress = () => {
    dispatch(purgeCaseTests());
    closeModal();
    props.closePopup();
  }
  return (
    <Popup
      //modal
      open={open}
      closeOnDocumentClick
      onClose={closeModal}
      contentStyle={{
        borderColor: "rgb(49, 49, 218)",
        borderWidth: "3px",
        width: "40%",
      }}
    >
      <div className="finish-popup">
        <div className="text-header">
          <p>Are you sure you want to delete all progress made?</p>
        </div>
        <div className="button-block">
          <div className="button" onClick={deleteProgress}>
            {<BlueBigButton link={`/`} text="Yes" />}
          </div>
          {
            <div className="button">
              <BlueBigButton onClick={() => closeModal} text="No" />
            </div>
          }
        </div>
      </div>
    </Popup>
  );
};

export default resetPopup;

import React, { useState } from "react";
import "./audioTest.css";
import { Row, Col } from "reactstrap";
import BlueBigButton from "../blueBigButton/blueBigButton";

type AudioTestProps = {
  question: string;
  labels: string[];
  onClick: (selectedOption: string) => void;
};

const AudioTest = (props: AudioTestProps) => {
  const [selectedOption, setSelectedOption] = useState<string>(
    props.labels[0]
  );

  const handleOptionChange = (selectedOption: string) => {
    setSelectedOption(selectedOption);
  };
  //console.log("this props data", this.props.classes)
  return (
    <div>
      <Row>
        <Col md={{ size: 6, order: 1, offset: 3 }}>
          <div className="listener-test-lung">
            <form className="test-form">
              <div className="form-line full">
                <div className="question">{props.question}</div>
                <input
                  type="radio"
                  checked={selectedOption === props.labels[0]}
                  onChange={() => handleOptionChange(props.labels[0])}
                  id={props.labels[0]}
                />
                <label htmlFor={props.labels[0]}>
                  <span />
                  {props.labels[0]}
                </label>
                <input
                  type="radio"
                  checked={selectedOption === props.labels[1]}
                  onChange={() => handleOptionChange(props.labels[1])}
                  id={props.labels[1]}
                />
                <label htmlFor={props.labels[1]}>
                  <span />
                  {props.labels[1]}
                </label>
                <input
                  type="radio"
                  checked={selectedOption === props.labels[2]}
                  onChange={() => handleOptionChange(props.labels[2])}
                  id={props.labels[2]}
                />
                <label htmlFor={props.labels[2]}>
                  <span />
                  {props.labels[2]}
                </label>
                <input
                  type="radio"
                  checked={selectedOption === props.labels[3]}
                  onChange={() => handleOptionChange(props.labels[3])}
                  id={props.labels[3]}
                />
                <label htmlFor={props.labels[3]}>
                  <span />
                  {props.labels[3]}
                </label>
              </div>
              <div className="form-line half">
                <BlueBigButton
                  onClick={() => props.onClick(selectedOption)}
                  text={"check"}
                  />
              </div> 
            </form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AudioTest;

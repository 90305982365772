// caseDetailsSlice.ts

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CaseDetailsType } from '../models/case'; // Assume this is your model
import { casesSlice } from './caseSlice';

interface CaseDetailsState {
  details: Record<string, CaseDetailsType | undefined>;
  isLoading: boolean;
  error: string | null;
}

const initialState: CaseDetailsState = {
  details: {},
  isLoading: false,
  error: null
};

export const fetchCaseDetails = createAsyncThunk(
    'caseDetails/fetchCaseDetailsById',
    async ({ moduleId, caseId }: { moduleId: string; caseId: string }, { dispatch }) => {
      const response = await fetch(`/modules/${moduleId}/cases/${caseId}/details.json`);
      const details = await response.json() as CaseDetailsType;
      
      // Dispatch an action to update the case with these details
      console.log("details", details)
      dispatch(casesSlice.actions.updateCaseWithDetails({ caseId, details }));
  
      return details; // You can still return details if needed elsewhere
    }
  );
  

export const caseDetailsSlice = createSlice({
  name: 'caseDetails',
  initialState,
  reducers: {
    // Your reducers here
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCaseDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCaseDetails.fulfilled, (state, action) => {
        const { moduleId, caseId } = action.meta.arg;
        console.log("action.meta.arg", moduleId, caseId)
        state.details[`${moduleId}-${caseId}`] = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchCaseDetails.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to fetch case details';
        state.isLoading = false;
      });
  }
});

export default caseDetailsSlice.reducer;

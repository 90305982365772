import React from 'react';
import { Row, Col } from 'reactstrap';
import PageChooser from './PageChooser'
import IntroOne from './IntroOne';
import LearningAim from './LearningAim';
import TromsoStudy from './TromsoStudy';
import Interactivity from './Interactivity';
import SoundRecording from './SoundRecording';
import LungSound from './LungSound';
import HeartSound from './HeartSound';
import Symptoms from './Symptoms';
import SpirometryPulseOximetry from './SpirometryPulseOximetry';
import EcgEcho from './EcgEcho';
import BloodTests from './BloodTests';
import PrivacyEthics from './PrivacyEthics';
import Literature from './Literature';

import './Intro.css'

class Intro extends React.Component {
 state = {
  page: "Learning aim"
 }
  constructor(props){
    super(props)
    this.setPageState = this.setPageState.bind(this);
  }

  setPageState(page) {
    this.setState({page: page})
  }

  renderPage(){
    if (this.state.page === "Intro"){
      return (<IntroOne></IntroOne>)
    }
    else if (this.state.page === "Learning aim"){
      return (<LearningAim></LearningAim>)
    }
    else if (this.state.page === "Tromso study"){
      return(<TromsoStudy></TromsoStudy>)
    }
    else if (this.state.page === "Interactivity") {
      return(<Interactivity></Interactivity>)
    }
    else if (this.state.page === "Sound recording"){
      return(<SoundRecording></SoundRecording>)
    }
    else if (this.state.page === "Heart sound"){
      return(<HeartSound></HeartSound>)
    }
    else if (this.state.page === "Lung sound"){
      return(<LungSound></LungSound>)
    }
    else if (this.state.page === "Symptoms"){
      return(<Symptoms></Symptoms>)
    }
    else if (this.state.page === "SpirometryPulseOximetry"){
      return(<SpirometryPulseOximetry></SpirometryPulseOximetry>)
    }
    else if (this.state.page === "Ecg echo"){
      return(<EcgEcho></EcgEcho>)
    }
    else if (this.state.page === "Blood tests"){
      return(<BloodTests></BloodTests>)
    }
    else if (this.state.page === "Privacy and ethics"){
      return(<PrivacyEthics></PrivacyEthics>)
    }
    else if (this.state.page === "Literature"){
      return(<Literature></Literature>)
    }
  }

  render(){
    return (
      <div className="main-content">
         <div className="auscultation-content-wrapper">
           <div className="container">

            <Row>

              <Col md={{ size: 9, order: 1, offset: 0}}>
                {this.renderPage()}
              </Col>
              <Col md={{ size: 3, order: 2, offset: 0}}>
                <PageChooser
                page = {this.state.page}
                setPage = {this.setPageState}
                >
                </PageChooser>
              </Col>
            </Row>
           </div>
         </div>
    </div>)
  }
}

export default Intro;

import { Row, Col } from "reactstrap";
import CaseBadge from "../components/caseBadge/caseBadge";
import { useAppDispatch, useAppSelector } from "../hooks";
import { fetchCases } from "../reducers/caseSlice";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

type CasesParams = {
  moduleId: string;
};

//const Cases:<Casee = ({ match:  CasesProps }) => {
const Cases = () => {
  const { moduleId } = useParams<CasesParams>();
  const dispatch = useAppDispatch();
  const { cases } = useAppSelector((state) => state.cases);
  const { caseTests } = useAppSelector((state) => state.caseTests);

  useEffect(() => {
    if (moduleId) {
      dispatch(fetchCases(moduleId));
    }
  }, [dispatch, moduleId]);

  const createBadges = () => {
    const badges = [];
    const caseKeys = Object.keys(cases);

    // Srt the keys (assuming they are numeric)
    caseKeys.sort((a, b) => parseInt(a) - parseInt(b));

    // Now iterate over the sorted keys
    for (let i = 0; i < caseKeys.length; i++) {
      const caseKey = caseKeys[i];
      const caseItem = cases[caseKey];
      const newTo = {
        pathname: `/modules/${moduleId}/cases/${caseItem.id}`,
      };

      badges.push(
        <CaseBadge
          link={newTo}
          title={caseItem.name}
          explanation={caseItem.title}
          key={caseItem.id}
          caseTest={caseTests[caseItem.id]}
        />
      );
    }
    return badges;
  };

  return (
    <div>
      <div className="container">
        <div className="main-content">
          <Row>
            <Col md={{ size: 8, order: 2, offset: 2 }}>
              <h3> Module </h3>
              <p>Select a case to start</p>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 8, order: 2, offset: 2 }}>
              <div className="profile-badges-container">{createBadges()}</div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Cases;

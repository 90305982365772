import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { CaseDetailsType, CaseType } from '../models/case';

// Define a type for the slice state
interface CasesState {
  cases: Record<string, CaseType>;
  isLoading: boolean;
  error: string | null;
  
}

  

const initialState: CasesState = {
  cases: {},
  isLoading: false,
  error: null
};

  
export const casesSlice = createSlice({
  name: "cases",
  initialState,
  reducers: {
    updateCaseWithDetails(state, action: PayloadAction<{ caseId: string, details: CaseDetailsType }>) {
      const { caseId, details } = action.payload;
      const existingCase = state.cases[caseId];
      if (existingCase) {
        existingCase.details = details; // Add details to the case
      }
    },
    // ... other reducers ...
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCases.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCases.fulfilled, (state, action) => {
        state.isLoading = false;
        
        // Transform the array into a record
        const newCases = action.payload.reduce((acc, currentCase) => {
          acc[currentCase.id.toString()] = currentCase;
          return acc;
        }, {} as Record<string, CaseType>);
      
        state.cases = newCases;
        state.error = null;
      })
      
      .addCase(fetchCases.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch modules';
      });
  }
});

export const fetchCases = createAsyncThunk(
  'cases/fetchCasesByModuleId',
  // Declare the type your function argument here:
  async (moduleId: string) => {
    const response = await fetch(`/modules/${moduleId}/cases.json`)
    // Inferred return type: Promise<MyData>
    return (await response.json()) as CaseType[]
  }
)

export const fetchCaseDetails = createAsyncThunk(
  'caseDetails/fetchCaseDetailsById',
  async ({ moduleId, caseId }: { moduleId: string; caseId: string }, { dispatch }) => {
    console.log("fetchCaseDetailsById")
    const response = await fetch(`/modules/${moduleId}/cases/${caseId}/details.json`);
    const details = await response.json() as CaseDetailsType;
    
    // Dispatch an action to update the case with these details
    dispatch(casesSlice.actions.updateCaseWithDetails({ caseId, details }));
    console.log(details)
    return details; // You can still return details if needed elsewhere
  }
);


//export modulesSlice.actions

export default casesSlice.reducer
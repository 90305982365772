import React from 'react';
import { Row, Col } from 'reactstrap';

class EcgEcho extends React.Component {
 
  render(){
    return (
    <div>
    <Row>
      <Col md={{ size: 5, order: 0, offset: 0 }}>

      <div className="intro-image-container">
        <div className="ecgecho-image">
      </div>
    </div>
    </Col>
      <Col md={{ size: 6, order: 0, offset: 0 }}>

      <div className="content-block">
        <h3 className="upper">ECG and echocardiography</h3>
        <p>
          When ECG was performed, the twelve standard leads were applied.
        </p>
        <p>
        Echocardiographic assessment was performed with a GE Vivid E9 scanner (GE Medical, Horten, Norway). Echopac version 113 was used for offline image reading. Ultrasound examination was performed in the left lateral decubitus position. 
        </p>
        <p>
        Only one echocardiographic video is shown from each case. For the interpretation, supervision by a cardiologist will be helpful.
        </p>
      </div>
      </Col>
    </Row>

    </div>

    )
  }
}

export default EcgEcho;

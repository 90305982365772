import React from 'react';
import { Row, Col } from 'reactstrap';

class SoundRecording extends React.Component {
 
  render() {
    return (
    <div>
    <Row>

      <Col md={{ size: 11, order: 0, offset: 0 }}>

      <div className="content-block">
        <h3 className="upper">Symptoms</h3>
        <p>
The participants of the Tromsø study answered questions on previous diseases, smoking habit and respiratory symptoms. The mMRC questionnaire on dyspnoea was used
        </p>
      </div>
      </Col>

    </Row>
    <Row>
    <Col md={{ size: 10, order: 0, offset: 0 }}>
    <div className="content-block">
      <p><strong>The modified Medical Research Council (mMRC) dyspnea scale. Grade of dyspnea</strong></p>
    </div>
    </Col>
    </Row>
    <Row>
      <Col md={{ size: 11, order: 0, offset: 0 }}>
      <div className="symptoms-image-container">
        <div className="symptoms-image">
      </div>
    </div>
    </Col>
    </Row>

    <Row>
    <Col md={{ size: 11, order: 0, offset: 0 }}>

    <div className="content-block">

      <p><strong>Other questions on symptoms</strong></p>
      <ul>
        <li>
        Have you had symptoms of common cold, bronchitis, or other airway infection the last 7 days?
        </li>
        <li>
        How is your breathing today compared to normal?
        </li>
        <li>
        Do you cough about daily for some periods of the year?
        </li>
        <li>
        Do you get pain or discomfort in the chest when walking up hills or stairs, or walking fast on level ground?
        </li>
      </ul>
    </div>
    </Col>
    </Row>

    </div>

    )
  }
}

export default SoundRecording;

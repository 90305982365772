import { Col, Row } from "reactstrap";
import "../../App.css";
import "./spirometry.css";
import { useAppDispatch } from "../../hooks";
import { CaseType } from "../../models/case";
import { CaseTestType } from "../../models/caseTest";
import { addSpirometryAnswer } from "../../reducers/caseTestSlice";
import BravoBlock from "../bravoBlock/bravoBlock";
import FormTestHorizontal from "../formTestHorizontal/formTestHorizontal";

type SpirometryProps = {
  specificCase: CaseType;
  caseTest: CaseTestType;
  onClick: () => void;
};

const Spirometry = (props: SpirometryProps) => {
  const classes = ["normal", "obstructive", "restrictive", "both"];
  const dispatch = useAppDispatch();

  const submitSpirometryAnswer = (answer: string) => {
    dispatch(addSpirometryAnswer({ caseId: props.specificCase.id, answer }));
  };

  const testOrResult = () => {
    // ... other code ...

    if (props.caseTest.examsAnswer.spirometry === "") {
      return (
        <FormTestHorizontal
          onClick={(answer: string) => submitSpirometryAnswer(answer)}
          classes={classes}
          question={"Results"}
        />
      );
    } else {
      return (
        <Row>
          <Col md={{ size: 6, order: 1, offset: 0 }}>
            <div className="bravo-block-tets">
              <BravoBlock
                correctAnswer={props.caseTest.exams.spirometry.answer}
                userAnswer={props.caseTest.examsAnswer.spirometry}
                buttonType={"onclick"}
                onClick={props.onClick}
              />
            </div>
          </Col>
          <Col md={{ size: 4, order: 5, offset: 0 }}>
            <div className="result-description">
              <div className="header-one-markup">Description</div>
              <p>{props.specificCase.details!.spirometry.description}</p>
            </div>
          </Col>
        </Row>
      );
    }
  };

  return (
    <div className="spirometry-container">
      <Row>
        <Col md={{ size: 1, order: 0, offset: 0 }}>
          <div className="header-text">
            <p>spirometry</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={{ size: 12, order: 1, offset: 0 }}>
          <div>
            <img
              className="image"
              src={props.specificCase.details!.spirometry.imagePath}
              alt="Image missing"
            />
          </div>
        </Col>
      </Row>
      {testOrResult()}
    </div>
  );
};

export default Spirometry;

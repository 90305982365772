import "../App.css";
import "./followup.css";
import { Row, Col } from "reactstrap";
//import FollowupExam from './followupExam/followupExam';
import { useNavigate, useParams } from "react-router-dom";
import CaseStepper from "../components/caseStepper/caseStepper";
import FollowupTest from "../components/followupTest/FollowupTest";
import { addFirstChoiceAnswer } from "../reducers/caseTestSlice";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useEffect, useState } from "react";
import Ecg from "../components/ecg/ecg";
import Spirometry from "../components/spirometry/spirometry";
import Crp from "../components/crp/crp";
import ProBnp from "../components/proBnp/proBnp";
import NoTestsPopup from "../components/noTestsPopup/noTestsPopup";
import BlueBigButton from "../components/blueBigButton/blueBigButton";
import PulseOxymetry from "../components/pulseOxymetry/pulseOxymetry";
import Echo from "../components/echo/echo";
import Hb from "../components/hb/hb";

type FollowupProps = {
  moduleId: string;
  caseId: string;
};

type labelType = {
  label: string;
  opacity: string;
};

type LabelMap = {
  [key: string]: labelType;
};
const labelMapIntialState: LabelMap = {
  ecg: { label: "ECG", opacity: "100%" },
  crp: { label: "CRP", opacity: "100%" },
  probnp: { label: "NT-proBNP", opacity: "100%" },
  spirometry: { label: "Spirometry", opacity: "100%" },
  pulseOxymetry: { label: "Pulse oximetry", opacity: "100%" },
  echo: { label: "Echocardiography", opacity: "100%" },
  hb: { label: "Hemoglobin", opacity: "100%" },
  none: { label: "No more tests", opacity: "100%" },
};

const Followup = () => {
  const { moduleId, caseId } = useParams<FollowupProps>();
  const dispatch = useAppDispatch();
  const [activeFollowupTest, setActiveFollowupTest] = useState<string>("");
  console.log("Render followup")
  console.log(labelMapIntialState)
  const [labelMap, setLabelMap] = useState<LabelMap>(labelMapIntialState);
  const [testCount, setTestCount] = useState<number>(0);
  const navigate = useNavigate();

  const specificCase = useAppSelector(
    (state) => state.cases.cases[caseId ?? ""]
  );

  const caseTest = useAppSelector((state) =>
    caseId ? state.caseTests.caseTests[caseId] : undefined
  );

  const submitAnswer = (key: keyof LabelMap, answer: string) => {
    if (caseId) {
      // increment test count
      setTestCount(testCount + 1);
      if (caseTest?.examsAnswer.firstChoice === "") {
        dispatch(addFirstChoiceAnswer({ caseId, answer }));
      }
      // Set the active test and update opacity for the clicked test
      setActiveFollowupTest(String(key)); // Update this line to set key instead of answer
      setOpacity(key, "50%");
    }
  };

  useEffect(() => {
    return () => {
      setLabelMap(labelMapIntialState);
    };
  }, [navigate]);

  const setOpacity = (key: keyof LabelMap, opacity: string) => {
    const newLabelMap = { ...labelMap, [key]: { ...labelMap[key], opacity } };
    setLabelMap(newLabelMap);
  };
  

  const renderFollowupTests = () => {
    switch (activeFollowupTest) {
      case "": {
        // Get all the keys from the labelMap
        const testKeys = Object.keys(labelMap);

        // Map each key to a FollowupTest component
        const tests = testKeys.map((key) => (
          <FollowupTest
            key={key}
            dataKey={key} // Passing the key
            data={labelMap[key as keyof typeof labelMap]}
            onClick={submitAnswer}
          />
        ));

        return (
          <div className="followup-container">
            <div className="followup-header">
              <h5>Which supplementary exam would be your first choice?</h5>
            </div>
            {tests}
          </div>
        );
        return tests;
      }
      case "ecg": {
        return (
          <div className="followup-container">
            <Ecg
              specificCase={specificCase}
              caseTest={caseTest!}
              onClick={() => setActiveFollowupTest("")}
            ></Ecg>
          </div>
        );
      }
      case "crp": {
        return (
          <div className="followup-container">
            <Crp
              specificCase={specificCase}
              caseTest={caseTest!}
              onClick={() => setActiveFollowupTest("")}
            ></Crp>
          </div>
        );
      }
      case "probnp": {
        return (
          <div className="followup-container">
            <ProBnp
              specificCase={specificCase}
              caseTest={caseTest!}
              onClick={() => setActiveFollowupTest("")}
            ></ProBnp>
          </div>
        );
      }
      case "spirometry": {
        return (
          <div className="followup-container">
            <Spirometry
              specificCase={specificCase}
              caseTest={caseTest!}
              onClick={() => setActiveFollowupTest("")}
            ></Spirometry>
          </div>
        );
      }
      case "pulseOxymetry": {
        return (
          <div className="followup-container">
            <PulseOxymetry
              specificCase={specificCase}
              caseTest={caseTest!}
              onClick={() => setActiveFollowupTest("")}
            ></PulseOxymetry>
          </div>
        );
      }
      case "hb": {
        return (
          <div className="followup-container">
            <Hb
              specificCase={specificCase}
              caseTest={caseTest!}
              onClick={() => setActiveFollowupTest("")}
            ></Hb>
          </div>
        );
      }
      case "echo": {
        return (
          <div className="followup-container">
            <Echo
              specificCase={specificCase}
              caseTest={caseTest!}
              onClick={() => setActiveFollowupTest("")}
            ></Echo>
          </div>
        );
      }
      case "none": {
        if (testCount > 1) {
          navigate(
            "/modules/" + moduleId + "/cases/" + caseId + "/case_result"
          );
        } else {
          return (
            <NoTestsPopup
              caseTest={caseTest!}
              closePopup={() => setActiveFollowupTest("")}
              link={
                "/modules/" + moduleId + "/cases/" + caseId + "/case_result"
              }
              testCount={testCount}
            ></NoTestsPopup>
          );
        }
      }
    }
  };

  const firstChoiceText = () => {
    if (caseTest?.examsAnswer.firstChoice !== "" && activeFollowupTest === "") {
      return (
        <div className="choosen-text">
          {`You have chosen ${caseTest?.examsAnswer.firstChoice} as the first test`}
        </div>
      );
    }
  };
  const completeButton = () => {
    if (caseTest?.examsAnswer.firstChoice !== "" && activeFollowupTest === "") {
      return (
        <div className="followup-button-block">
          <BlueBigButton
            link={"/modules/" + moduleId + "/cases/" + caseId + "/case_result"}
            text="Complete case"
          />
        </div>
      );
    }
  };

  return (
    <div className="container">
      <Col className="main-content">
        <CaseStepper activeStep={3}></CaseStepper>
        <div className="content-container">
          <Row>
            <Col md={{ size: 10, order: 0, offset: 1 }}>
              {renderFollowupTests()}
              {/*renderActiveFollowupTest()*/}
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 4, order: 0, offset: 4 }}>
              {firstChoiceText()}
              {completeButton()}
            </Col>
          </Row>
        </div>
      </Col>
    </div>
  );
};

export default Followup;

import React from "react";
import BlueBigButton from "../blueBigButton/blueBigButton";
import "./formTestVertical.css";

type FormTestVerticalProps = {
  question: string;
  classes: string[];
  onClick: (answer: string) => void;
};

const FormTestVertical = (props: FormTestVerticalProps) => {
  const [selectedOption, setSelectedOption] = React.useState(props.classes[0]);

  const generateButtons = () => {
    const buttons = [];
    //console.log("classes", this.props.classes)
    for (let i = 0; i < props.classes.length; i++) {
      buttons.push(
        <div key={i}>
          <input
            type="radio"
            checked={selectedOption === props.classes[i]}
            onChange={() => setSelectedOption(props.classes[i])}
            id={props.classes[i]}
          />
          <label htmlFor={props.classes[i]}>
            <span />
            {props.classes[i]}
          </label>
        </div>
      );
    }
    return <div className= "form-vertical">{buttons}</div>;
  };
  return (
    <div className="listener-test">
      <form className="test-form">
        <div className="form-line full">
          <div className="question">{props.question}</div>
          {generateButtons()}
          <div className="form-line full">
            <BlueBigButton
              onClick={() => props.onClick(selectedOption)}
              text={"check"}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormTestVertical;

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import ErrorPage from "./error-page";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Header from "./components/header/header";
import Modules from "./routes/modules";
import Cases from "./routes/cases";
import CaseDetails from "./routes/caseDetails";
import LungAuscultation from "./routes/lungAuscultation/lungAuscultation";
import HeartAuscultation from "./routes/heartAuscultation/heartAuscultation";
import BeforeFollowup from "./routes/beforeFollowup";
import Followup from "./routes/followup";
import Intro from "./Intro/Intro";
import CaseResult from "./routes/caseResult/caseResult";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Header />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Modules />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/modules/:moduleId",
        element: <Cases />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/modules/:moduleId/cases/:caseId",
        element: <CaseDetails />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/modules/:moduleId/cases/:caseId/lung_auscultation",
        element: <LungAuscultation />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/modules/:moduleId/cases/:caseId/heart_auscultation",
        element: <HeartAuscultation />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/modules/:moduleId/cases/:caseId/before_followup",
        element: <BeforeFollowup />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/modules/:moduleId/cases/:caseId/followup",
        element: <Followup />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/intro",
        element: <Intro />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/modules/:moduleId/cases/:caseId/case_result",
        element: <CaseResult />,
        errorElement: <ErrorPage />,
      }
    ],
  },
]);

//import { RouterProvider } from '@remix-run/react';
//import { router } from './path-to-your-router'; // Import your router instance

function App() {
  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;

/*


// ... normal setup, create store and persistor, import components etc.

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RootComponent />
      </PersistGate>
    </Provider>
  );
};
*/
/*    <Route path="/admin/cases" component={AdminCase} />
    <Route path="/admin/new_case" component={NewCase}/>*/

/*    <Route exact path="/" component={Home} />
    <Route exact path="/module/:module_id/case/:id" component={Case} />
    <Route exact path="/module/:id" component={Module} />

    <Route path="/module/:module_id/case/:case_id/lung_auscultation" component={LungAuscultation} />
    <Route path="/module/:module_id/case/:case_id/heart_auscultation" component={HeartAuscultation} />
    <Route path="/module/:module_id/case/:case_id/before_followup" component={BeforeFollowup} />
    <Route path="/module/:module_id/case/:case_id/followup" component={Followup} />
    <Route path="/module/:module_id/case/:case_id/case_result" component={CaseResult} />
*/

import React from 'react';
import { Row, Col } from 'reactstrap';

class SoundRecording extends React.Component {
 
  render(){
    return (
    <div>
    <Row>

      <Col md={{ size: 8, order: 0, offset: 0 }}>

      <div className="content-block">
        <h3 className="upper">Sound recording</h3>
        <p>
          This equipment was used: Sennheiser microphone MKE 2-EW, 
          (Sennheiser electronic GmbH, Wedemark, Germany) placed in the tube of a 
          Littmann Classic II stethoscope (3M, Maplewood MN, USA) at 10 cm from 
          the headpiece and a Sennheiser wireless system. Audio filters or other digital 
          pre or post-processing techniques were not used.
        </p>
      </div>
      </Col>
      <Col md={{ size: 4, order: 0, offset: 0 }}>

      <div className="image-container">
        <div className="sound-recording-image">
      </div>
    </div>
    </Col>
    </Row>
    <Row>
        <Col md={{ size: 12, order: 0, offset: 0 }}>
          <div className="content-block">
            <h3 className="upper">Auscultation sites</h3>
          </div>
        </Col>
    </Row>
    <Row>

        <Col md={{ size: 12, order: 0, offset: 0 }}>
          <div className="intro-image-container">
            <div className="auscultation-image">
            </div>
          </div>
        </Col>


        </Row>

    <Row>
      <div className="content-block">
            <h3 className="upper">Spectrograms</h3>
        <p>
The sounds are presented graphically as spectrograms with time shown horisontally and pitch vertically. The amplitude is shown by the intensity of grey/black.  Experienced observers may be able to read abnormal sounds from the spectrograms.
(Examples are following)
        </p>
      </div>
    </Row>
    </div>

    )
  }
}

export default SoundRecording;

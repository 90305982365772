import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ModuleType } from '../models/module';

// Define a type for the slice state
interface ModulesState {
  modules: ModuleType[];
  isLoading: boolean;
  error: string | null;
}

  

const initialState: ModulesState = {
  modules: [],
  isLoading: false,
  error: null
};

  
export const modulesSlice = createSlice({
  name: "modules",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchModules.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchModules.fulfilled, (state, action) => {
        state.isLoading = false;
        state.modules = action.payload; // Assuming the API returns the full modules array
        state.error = null;
      })
      .addCase(fetchModules.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch modules';
      });
  }
});

export const fetchModules = createAsyncThunk(
  'modules/fetchModules',
  // Declare the type your function argument here:
  async () => {
    const response = await fetch(`/modules/modules.json`)
    // Inferred return type: Promise<MyData>
    return (await response.json()) as ModuleType[]
  }
)

//export modulesSlice.actions

export default modulesSlice.reducer
import React from 'react';
import './AudioPlayer.css';
import './Needle.css';

function add(a, b) {
  return a + b;
}

async function renderNeedle(processor, analyzer, needle, audioLength, canvasRef, playing, pausedAt, resetAudio) {

  if ((processor === '') && (audioLength != 0)) {
    //console.log("Processor is '', returning");
  }
  let totalNeedlePath =  canvasRef.current.children[0].clientWidth / canvasRef.current.offsetWidth
  if (playing === false) {
    return;
  }
  processor.onaudioprocess = function (ev) {
    let audioData = new Uint8Array(analyzer.frequencyBinCount);
    analyzer.getByteFrequencyData(audioData);
    let needleOffset = 0;
    if (audioData.reduce(add, 0) > 0) {
      const currentTime = ev.playbackTime;
      let needlePos = needleOffset + (currentTime + pausedAt / 1000) / audioLength * (totalNeedlePath * 100 - needleOffset);
      needle.current.style.left = `${needlePos.toString()  }%`;
      if (needle.current.offsetLeft > canvasRef.current.offsetWidth-100){
        if (needle.current.offsetLeft > canvasRef.current.offsetWidth/2){

          canvasRef.current.scrollLeft+=5
        }
        console.log("need to scroll")
      }
      var totalTime = currentTime + pausedAt / 1000;
      console.log("totalTime", totalTime)
      console.log("currentTime", currentTime)
      if(audioLength - totalTime <= 0){
        resetAudio();
        canvasRef.current.scrollLeft = 0;
        needle.current.style.left = "0%";
      };
      //if(needle.)
    };
  };
}

class Needle extends React.Component {
  state = {
    needlePos: '0%',
  }

  constructor(props) {
    super(props);
    this.needleRef = React.createRef();
  }

  componentDidMount() {
    // renderNeedle(this.props.analyzeContext, this.props.processor, this.props.analyzer, this.needleRef, this.props.audioLength, this.props.canvasRef)
  }

  render() {
    if (this.props.audioLength != 0) {
      renderNeedle(this.props.processor, this.props.analyzer, this.needleRef, this.props.audioLength, this.props.canvasRef, this.props.playing, this.props.stoppedAt, this.props.resetAudio);
    }
    return (
      <div className="needle" ref={this.needleRef} />
    );
  }
}

export default Needle;

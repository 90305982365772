import React from "react";

import "../../App.css";
import "./pulseOxymetry.css";
import { Row, Col } from "reactstrap";
import { CaseType } from "../../models/case";
import { CaseTestType } from "../../models/caseTest";
import { useAppDispatch } from "../../hooks";
import { addPulseOxymetryAnswer } from "../../reducers/caseTestSlice";
import ReactMinimalPieChart from "react-minimal-pie-chart";
import FormTestVertical from "../formTestVertical/formTestVertical";
import BravoBlock from "../bravoBlock/bravoBlock";

type PulseOxymetryProps = {
  specificCase: CaseType;
  caseTest: CaseTestType;
  onClick: () => void;
};

const PulseOxymetry = (props: PulseOxymetryProps) => {
  const classes = ["normal", "decreased"];
  const dispatch = useAppDispatch();
  const submitPulseOxymetryAnswer = (answer: string) => {
    console.log("dispatching answer", answer);
    dispatch(addPulseOxymetryAnswer({ caseId: props.specificCase.id, answer }));
  };

  const testOrResult = () => {
    if (props.caseTest.examsAnswer.pulseOxymetry === "") {
      return (
        <FormTestVertical
          onClick={(answer: string) => submitPulseOxymetryAnswer(answer)}
          classes={classes}
          question={"Results"}
        />
      );
    } else {
      return (
        <Row>
          <Col md={{ size: 5, order: 1, offset: 0 }}>
            <div className="bravo-block-tets">
              <BravoBlock
                correctAnswer={props.caseTest.exams.pulseOxymetry.answer}
                userAnswer={props.caseTest.examsAnswer.pulseOxymetry}
                buttonType={"onclick"}
                onClick={props.onClick}
              />
            </div>
          </Col>
          <Col md={{ size: 5, order: 2, offset: 1 }}>
            <div className="pulse-oxymetry-result-container">
              <div className="pulse-oxymetry-reference-image"></div>
            </div>
          </Col>
        </Row>
      );
    }
  };

  return (
    <div className="pulse-oxymetry-container">
      <Row>
        <Col md={{ size: 6, order: 0, offset: 0 }}>
          <div className="header-text">
            <p>Pulse Oximetry</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={{ size: 12, order: 1, offset: 0 }}>
          <div className="followup-text">
            <div className="pulse-oxymetry-pie-chart">
              <ReactMinimalPieChart
                data={[
                  {
                    value: parseInt(
                      props.specificCase.details!.pulseOxymetry.value,
                      10
                    ),
                    title: "10%",
                    color: "#1f5fc8",
                  },
                ]}
                totalValue={100}
                lineWidth={20}
                startAngle={270}
                label={() =>
                  props.specificCase.details!.pulseOxymetry.value + "%"
                }
                labelStyle={{
                  fontSize: "15px",
                  fontFamily: "sans-serif",
                  fontWeight: 1000,
                  //  key: "90%",
                  //    textAnchor: "90%"
                }}
                labelPosition={0}
              />
            </div>
          </div>
        </Col>
      </Row>

      {testOrResult()}
    </div>
  );
};
/*class PulseOxymetry extends React.Component {
  state = {
    testState: 'testState',
    correctAnswer: this.props.case.pulse_oxymetry_test,
    userAnswer: '',
    classes: ['normal', 'decreased']
  }
  constructor(props) {
    super(props);
    this.exitClick = this.exitClick.bind(this);
    this.changeTestState = this.changeTestState.bind(this);
  }

  exitClick() {
    this.props.setFollowupState('followup');
  }

  changeTestState(testState){
    //console.log("changing test state", testState)
    this.setState({ userAnswer: testState })
    this.setState({ testState: 'userAnswered' })
  }


  testOrResult() {
    //console.log(this.state.testState)
    //console.log("in test or result props", this.props)
    if (this.state.testState === 'testState') {
      //console.log("rendering listenerTest", this.state.testState)

      return(

        <FormTestVertical 
                onClick={this.changeTestState} 
                classes={this.state.classes}
                question={"Select the range corresponsing to patient's values:"} >
        </FormTestVertical>)
    }
    else if (this.state.testState === 'userAnswered') {
      //console.log("rendering userAnswered", this.state.testState)  
      return(
            <Row>
              <Col md={{ size: 5, order:1, offset:0 }}>
                <div className="bravo-block-tets">
                <BravoBlock 
                   correctAnswer={this.state.correctAnswer}
                   userAnswer={this.state.userAnswer}
                   buttonType={'onclick'}
                   onClick={this.exitClick}>
                </BravoBlock>
                </div>
              </Col>
              <Col md={{ size: 5, order:2, offset:1 }}>

                <div className="pulse-oxymetry-result-container">
                  <div className="pulse-oxymetry-reference-image"></div>
                </div>
              </Col>
            </Row>
            )
    }

  }

  render() {
  //const labelProps = {
    //key: string;
    //x: number;
    //y: number;
    //dx: number;
    //dy: number;
    //textAnchor: "90%",
    //data: {
      // props.data entry extended with:
    //  degrees: number;
    //  startOffset: number;
    //  percentage: number;
    //}[];
    //dataIndex: number;
    //color: string;
    //style: { [key: string]: string | number };
  //};
    return (
      <div className="pulse-oxymetry-container">
        <Row>
          <Col md={{ size: 6, order: 0, offset: 0 }}>
            <div className="header-text">
              <p>Pulse Oximetry</p>
            </div>
          </Col>


        </Row>
        <Row>
        <Col md={{ size: 12, order: 1, offset: 0 }}>
          <div className="followup-text">
          <div className="pulse-oxymetry-pie-chart">
            <ReactMinimalPieChart
              data={[{
                  value: this.props.case.pulse_oxymetry,
                  title: "10%",
                  color: '#1f5fc8',
                }]}
              totalValue={100}
              lineWidth={20}
              startAngle={270}
              label={({}) =>
                Math.round(this.props.case.pulse_oxymetry) + '%'
              }
              labelStyle={{
                fontSize: '15px',
                fontFamily: 'sans-serif',
                fontWeight: 1000,
              //  key: "90%",
              //    textAnchor: "90%"
              }}
              labelPosition={0}
              />
          </div>
          </div>
        </Col>
        </Row>

            {this.testOrResult()}

      </div>
    );
  }
}*/
/* class ECG extends React.Component {
  render() {
    return (
      <div className="ECG-container">
      </div>
    );
  }
} */

export default PulseOxymetry;

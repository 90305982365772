import { AnyAction, combineReducers } from "@reduxjs/toolkit";
import modulesReducer from "./reducers/moduleSlice";
import caseDetailsReducer from "./reducers/caseDetailsSlice";
import casesReducer from "./reducers/caseSlice";
import caseTestReducer from "./reducers/caseTestSlice";
// configureStore.js

import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import thunk from "redux-thunk";
import { ThunkDispatch } from "redux-thunk";

//import rootReducer from './reducers'

export const rootReducer = combineReducers({
  modules: modulesReducer,
  cases: casesReducer,
  caseDetails: caseDetailsReducer,
  caseTests: caseTestReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  persistedReducer,

  composeEnhancers(applyMiddleware(thunk))
);
const persistor = persistStore(store);

export default { store, persistor };

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = ThunkDispatch<RootState, void, AnyAction> &
  typeof store.dispatch;

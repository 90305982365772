import React from "react";
import BlueBigButton from "../blueBigButton/blueBigButton";
import "./formTestHorizontal.css";

type FormTestHorizontalProps = {
  question: string;
  classes: string[];
  onClick: (answer: string) => void;
};

const FormTestHorizontal = (props: FormTestHorizontalProps) => {
  const [selectedOption, setSelectedOption] = React.useState<string>(
    props.classes[0]
  );

  const generateButtons = () => {
    const buttons = [];
    for (let i = 0; i < props.classes.length; i++) {
      buttons.push(
        <input
          key={"input" + i.toString()}
          type="radio"
          checked={selectedOption === props.classes[i]}
          onChange={() => setSelectedOption(props.classes[i])}
          id={props.classes[i]}
        />
      );
      buttons.push(
        <label htmlFor={props.classes[i]} key={"label" + i.toString()}>
          <span />
          {props.classes[i]}
        </label>
      );
    }
    return <div className= "form-horizontal">{buttons}</div>;
  };

  return (
    <div className="listener-test">
      <form className="test-form">
        <div className="form-line full">
          <div className="question">{props.question}</div>
          {generateButtons()}
          <div className="form-line full">
            <BlueBigButton
              onClick={() => props.onClick(selectedOption)}
              text={"check"}
            />
          </div>
        </div>
      </form>
    </div>
  );
};
export default FormTestHorizontal;

/*class FormTestVertical extends React.Component {
  state = {
    selectedOption: this.props.classes[0],
  };
  handleOptionChange(selectedOption) {
    this.setState({ selectedOption });
  }

  generateButtons = () => {
    const buttons = [];
    //console.log("classes", this.props.classes)
    for (let i = 0; i < this.props.classes.length; i++) {
      buttons.push(
        <input
          key={"input" + i.toString()}
          type="radio"
          checked={this.state.selectedOption === this.props.classes[i]}
          onChange={() => this.handleOptionChange(this.props.classes[i])}
          id={this.props.classes[i]}
        />
      );
      buttons.push(
        <label htmlFor={this.props.classes[i]} key={"label" + i.toString()}>
          <span />
          {this.props.classes[i]}
        </label>
      );
    }
    return buttons;
  };
  render() {
    return (
      <div className="listener-test">
        <form className="test-form">
          <div className="form-line full">
            <div className="question">{this.props.question}</div>
            {this.generateButtons()}
            <div className="form-line full">
              <BlueBigButton
                onClick={() => this.props.onClick(this.state.selectedOption)}
                text={"check"}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
*/
